<template>
  <div>

    <v-card
      v-for="(item,index) in  items"
      :key="item"
      class="py-2 px-10 mt-1 mb-5 elevation-5"
    >
      <v-card-title class="text-right">
        <custom-copy-value :item="item"></custom-copy-value>
        <span class="subtitle-2">
          สร้างเมื่อ {{ item.createdDate }} น. โดย {{ item.createdBy }}
        </span>

        <div class="flex-grow-1 blue"></div>

        <v-btn
          @click="update(item)"
          color="primary"
          class="mr-5"
        >Submit</v-btn>

        <v-btn
          text
          @click="deleteSanyayom(item)"
          color="error"
          class="ml-6"
        >
          <v-icon>delete</v-icon>ลบ
        </v-btn>
      </v-card-title>

      <v-row class="mt-3">
        <v-col cols="3">
          <render-input
            v-model="item.date"
            :input="fieldConfig.date"
          ></render-input>
        </v-col>
        <v-col cols="2">
          <render-input
            v-if="isAEON"
            v-model="item.payDay"
            :input="fieldConfig.payDay"
          ></render-input>
        </v-col>
        <v-col></v-col>
      </v-row>

      <template v-if="isAEON">
        <v-divider class="mt-n3 mb-3"></v-divider>
        <div
          v-for="(condition,index2) in item.sanyaYomPaymentConditions"
          :key="index2"
        >
          <v-row>
            <v-col cols="2">
              <render-input
                v-model="condition.beginRound"
                :input="fieldConfig.beginRound"
              ></render-input>
            </v-col>
            <v-col cols="2">
              <render-input
                v-model="condition.endRound"
                :input="fieldConfig.endRound"
              ></render-input>
            </v-col>
            <v-col cols="2">
              <render-input
                v-model="condition.minimumPayment"
                :input="fieldConfig.minimumPayment"
              ></render-input>
            </v-col>
            <v-col cols="2">
              <v-btn
                text
                color="error"
                @click="deleteRecord(item,index2)"
              >
                <v-icon>delete</v-icon> ลบ
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-btn
          v-if="isAEON"
          @click="addConditionRecord(item)"
          color="primary"
          class="mb-5"
        >เพิ่มเงื่อนไขจ่ายเงินพิเศษ</v-btn>
      </template>

      <v-row class="mt-3 ml-1">
        <v-btn
          text
          @click="download(item)"
        ><v-icon>download</v-icon> Download</v-btn>
      </v-row>
    </v-card>

    <v-btn
      @click="add()"
      color="primary"
      class="mb-12"
    >เพิ่มสัญญายอม</v-btn>
  </div>
</template>

<script>
import { Customer, InputType } from "../../js/constants";

export default {
  data() {
    return {
      caseId: null,
      items: [],
    };
  },
  created() {
    this.caseId = this.state.id;
    log("caseid", this.caseId);

    this.get();
  },

  methods: {
    get() {
      let url = "";
      if (this.isAEON) {
        url = `/case/${this.caseId}/sanyayom/aeon/get`;
      } else if (this.isRabbit) {
        url = `/case/${this.caseId}/sanyayom/rabbit/get`;
      }
      this.$http.get(url).then((res) => {
        this.items = res.items;
      });
    },
    add() {
      let url = "";
      if (this.isAEON) {
        url = `/case/${this.caseId}/sanyayom/aeon/create`;
      } else if (this.isRabbit) {
        url = `/case/${this.caseId}/sanyayom/rabbit/create`;
      }

      this.$http.post(url).then((res) => {
        this.get();
      });
    },
    update(item) {
      let url = "";
      if (this.isAEON) {
        url = `/case/${this.caseId}/sanyayom/aeon/update`;
      } else if (this.isRabbit) {
        url = `/case/${this.caseId}/sanyayom/rabbit/update`;
      }

      this.$http.put(url, item).then((res) => {
        this.showAlertSuccess();
        this.get();
      });
    },
    deleteSanyayom(item) {
      if (!confirm("คุณแน่ใจหรือไม่ที่จะลบรายการนี้ ?")) {
        return;
      }

      let url = `/case/${this.caseId}/sanyayom/${item.id}/delete`;
      this.$http.delete(url).then((res) => {
        this.get();
      });
    },

    download(item) {
      let url = "";
      if (this.isAEON) {
        url = `/case/${this.caseId}/sanyayom/aeon/${item.id}/download`;
      } else if (this.isRabbit) {
        url = `/case/${this.caseId}/sanyayom/rabbit/${item.id}/download`;
      }

      this.$http.downloadFile(url).then((res) => {});
    },
    addConditionRecord(item) {
      item.sanyaYomPaymentConditions.push({
        beginRound: null,
        endRound: null,
        minimumPayment: null,
      });
    },
    deleteRecord(item, index) {
      item.sanyaYomPaymentConditions.splice(index, 1);
    },
  },
  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
    isAEON() {
      return this.state.model.creditor.id == Customer.AEON_ID;
    },
    isRabbit() {
      return this.state.model.creditor.id == Customer.RABBIT_ID;
    },
    fieldConfig() {
      return {
        date: {
          name: "date",
          text: "วันที่",
          type: InputType.datePicker,
          rule: {
            required: true,
          },
        },
        payDay: {
          name: "date",
          text: "ชำระเงินทุกวันที่",
          type: InputType.number,
          decimal: 0,
          rule: {
            min_value: 1,
            max_value: 31,
            required: true,
          },
        },
        beginRound: {
          name: "beginRound",
          text: "งวดที่",
          type: InputType.number,
          decimal: 0,
          rule: {
            min_value: 1,
            max_value: 100,
            required: true,
          },
        },
        endRound: {
          name: "endRound",
          text: "ถึงงวดที่",
          type: InputType.number,
          decimal: 0,
          rule: {
            min_value: 1,
            max_value: 100,
            required: true,
          },
        },
        minimumPayment: {
          name: "minimumPayment",
          text: "จำนวนเงินชำระขั้นต่ำ",
          type: InputType.number,
          decimal: 0,
          rule: {
            min_value: 1,
            required: true,
          },
        },
      };
    },
  },
};
</script>

<style>
</style>