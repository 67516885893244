<template>
  <div class="theme--light">
    <show-model :text="model"></show-model>
    <v-row>
      <v-col cols="8">
        <v-row>
          <v-col>
            <render-case-action position="top"></render-case-action>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <custom-copy-value
                  :item="model"
                  :inline="true"
                  class="mb-2"
                ></custom-copy-value>

                <v-row>
                  <v-col
                    cols="2"
                    class="text-right"
                  >
                    <span class="font-weight-bold">ชื่อลูกหนี้</span>

                  </v-col>
                  <v-col>
                    {{$store.getters['caseinfo/deptorName']}}
                    <span class="error--text">{{errorDeptorName}}</span>
                    <button-inline @onClick="openDialogName"></button-inline>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="2"
                    class="text-right"
                  >
                    <span class="font-weight-bold">ลูกค้า</span>
                  </v-col>
                  <v-col>
                    <ind-input
                      :input="fieldConfig.creditor"
                      :value="model.creditor"
                      :canEdit="false"
                    ></ind-input>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="2"
                    class="text-right"
                  >
                    <span class="font-weight-bold">ที่อยู่</span>
                  </v-col>
                  <v-col md="8">
                    <v-textarea
                      outlined
                      auto-grow
                      :value="deptorAddress"
                      disabled
                      hint="Hint text"
                    ></v-textarea>
                  </v-col>
                  <v-col>
                    <button-inline
                      v-if="hasAuthorize"
                      @onClick="openDialogAddr"
                    ></button-inline>
                  </v-col>
                </v-row>
                <render-input :input="{type:'section'}"></render-input>

                <custom-secure-block
                  ref="secureBlock"
                  v-model="model"
                  :id="state.id"
                  @click-submit="clickSubmit"
                  :canEdit="hasAuthorize"
                >
                  <template v-slot:default="{disabled}">
                    <!-- row 1 -->
                    <v-row>
                      <v-col>
                        <render-input
                          :input="fieldConfig.nationality"
                          v-model="model.deptor.nationality"
                          :disabled="disabled"
                        ></render-input>
                      </v-col>
                      <v-col>
                        <render-input
                          :input="fieldConfig.idCardNo"
                          v-model="model.idCardNo"
                          :disabled="true"
                        ></render-input>
                      </v-col>
                    </v-row>
                    <!-- row 2 -->
                    <v-row>
                      <v-col>
                        <render-input
                          :input="fieldConfig.age"
                          v-model="model.deptor.age"
                          :disabled="disabled"
                        ></render-input>
                      </v-col>
                      <v-col></v-col>
                    </v-row>
                    <!-- row 3 -->
                    <v-row>
                      <v-col>
                        <render-input
                          :input="fieldConfig.noticeDate"
                          v-model="model.noticeDate"
                          :disabled="disabled"
                        ></render-input>
                      </v-col>
                      <v-col>
                        <render-input
                          :input="fieldConfig.deptSummaryDate"
                          v-model="model.deptSummaryDate"
                          :disabled="disabled"
                        ></render-input>
                      </v-col>
                    </v-row>
                    <!-- row 4 -->
                    <v-row>
                      <v-col>
                        <render-input
                          :input="fieldConfig.plaintDate"
                          v-model="model.plaintDate"
                          :disabled="disabled"
                        ></render-input>
                      </v-col>
                      <v-col></v-col>
                    </v-row>
                    <!-- row 5 -->
                    <v-row>
                      <v-col cols="6">
                        <render-input
                          :input="fieldConfig.court"
                          v-model="model.court"
                          :disabled="disabled"
                        ></render-input>
                      </v-col>
                      <v-col cols="6">
                        <render-input
                          :input="fieldConfig.lawyer"
                          v-model="model.lawyer"
                          :disabled="disabled"
                        ></render-input>
                      </v-col>

                    </v-row>

                    <!-- row 6 -->

                    <v-row>
                      <v-col cols="6">
                        <render-input
                          :input="fieldConfig.noFongReason"
                          v-model="model.noFongReason"
                          :disabled="disabled"
                        ></render-input>
                      </v-col>
                      <v-col cols="6">

                      </v-col>

                    </v-row>
                  </template>
                </custom-secure-block>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">
        <v-card class="mb-5">
          <v-card-title>ดาวน์โหลดเอกสาร</v-card-title>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                outlined
                class="ml-7 mb-1"
              >
                โนติสพิเศษ
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="downloadSpecialNotice(1)">
                <v-list-item-title>หนังสือแจ้งเสนอส่วนลดโครงการพิเศษ</v-list-item-title>
              </v-list-item>
              <v-list-item @click="downloadSpecialNotice(2)">
                <v-list-item-title>หนังสือแจ้งเตือนคำพิพากษา</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-card-text>
            <p
              v-for="(item,index) in downloadLinks"
              :key="index"
            >
              <button-download
                :text="item.text"
                :url="item.link"
              ></button-download>
            </p>

          </v-card-text>
        </v-card>
        <contract-upload-summary></contract-upload-summary>
        <upload-attachment-summary></upload-attachment-summary>
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="8">
        <v-row>
          <v-col>
            <render-case-action position="bottom"></render-case-action>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- modal name-->
    <v-row>
      <v-col>
        <v-dialog
          v-model="state.dialogName"
          persistent
          max-width="1200px"
          @keydown.esc="state.dialogName=false"
        >
          <case-deptor-name
            :canEdit="hasAuthorize"
            :case-id="state.id"
            @changed-deptor-name="changeDeptorName"
          ></case-deptor-name>
        </v-dialog>
      </v-col>
    </v-row>
    <!-- end modal -->
    <!-- modal addr-->
    <v-row>
      <v-col>
        <v-dialog
          v-model="state.dialogAddr"
          persistent
          max-width="1000px"
          @keydown.esc="state.dialogAddr=false"
        >
          <case-deptor-addr
            :case-id="state.id"
            :value="model.deptor.address"
            @changed-deptor-addr="changeDeptorAddr"
          ></case-deptor-addr>
        </v-dialog>
      </v-col>
    </v-row>
    <!-- end modal -->
  </div>
</template>

<script>
import {
  InputType,
  NamePrefix,
  StatusCase,
  StatusSue,
  UserRole,
} from "./../../js/constants";
import * as dropdown from "./../../js/dropdown-service";
import { addressToString, getNamePrefixString } from "./../../js/custom";

import CaseDeptorName from "./case-deptor-name";
import CaseDeptorAddr from "./case-deptor-addr";
import UploadAttachmentSummary from "./upload-attachment-summary";
import ContractUploadSummary from "./contract-upload-summary";
import IndInput from "./../master-render/ind-input";

import { fakeAbbr, getFakeUrl } from "../../_helpers/fake-url";

import { mapActions } from "vuex";
import RenderCaseAction from "./render-case-action";
import LawyerPaymentTab from "./lawyer-payment-tab";

export default {
  components: {
    "case-deptor-name": CaseDeptorName,
    "case-deptor-addr": CaseDeptorAddr,
    "upload-attachment-summary": UploadAttachmentSummary,
    "ind-input": IndInput,
    "render-case-action": RenderCaseAction,
    LawyerPaymentTab,
    "contract-upload-summary": ContractUploadSummary,
  },
  data() {
    return {
      inputType: InputType,
    };
  },
  created() {},
  methods: {
    ...mapActions("loading", ["showLoading", "hideLoading"]),
    cloneModel() {
      return _.cloneDeep(this.model);
    },
    clickSubmit() {
      let payload = this.cloneModel();
      this.showLoading();

      this.updateCase(payload)
        .then((res) => {
          this.model = res;
          this.$refs.secureBlock.cancelEditMode();
          this.showAlertSuccess();
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    changeDeptorName(item) {
      // this.model.deptor.name = { ...item }; //another way to clone object
      this.model.deptor.name = {
        firstName: item.firstName,
        lastName: item.lastName,
      };
      this.model.deptor.namePrefix = item.namePrefix;
      this.model.deptor.namePrefixRemark = item.namePrefixRemark;
    },
    changeDeptorAddr(savedResult) {
      this.$set(this.model.deptor, "address", savedResult);
      this.$store.dispatch("caseinfo/getCase");
    },
    openDialogName() {
      this.state.dialogName = true;
    },
    openDialogAddr() {
      this.state.dialogAddr = true;
    },
    updateCase(payload) {
      let url = "/case/" + this.state.id;
      return this.$http.put(url, payload);
    },
    anothrConditionDisabled() {
      let conditions = [];
      conditions.push(
        this.state.model.statusCase.id == StatusCase["ปิดเคส"].id
      );
      conditions.push(
        this.state.model.statusCase.id == StatusCase["คืนงาน"].id
      );
      conditions.push(this.state.model.pauseFlag == true);

      if (conditions.some((x) => x == true)) {
      }
    },
    downloadSpecialNotice(type) {
      let url = `/case/${this.state.id}/AdditionalNotice/download?type=${type}`;
      this.$http.downloadFile(url);
    },
  },
  computed: {
    hasAuthorize() {
      let permission = [
        UserRole.ADMIN,
        UserRole.MANAGEMENT,
        UserRole.BACK_OFFICE,
      ];
      return (
        this.hasPermission(permission) &&
        !this.$store.getters["caseinfo/anotherConditionDisabledEdit"]
      );
    },
    state() {
      return this.$store.state.caseinfo;
    },
    caseId() {
      return this.state.id;
    },
    model: {
      get() {
        return this.state.model;
      },
      set(val) {
        this.state.model = val;
      },
    },
    downloadLinks() {
      return [
        {
          text: "โนติส",
          link: `/case/${this.state.id}/notice/download`,
        },
        {
          text: "คำฟ้อง (รวมทุกเอกสาร)",
          link: `/case/${this.state.id}/plaint/download`,
        },
        {
          text: "คำฟ้อง",
          link: `/case/${this.state.id}/plaint/download/1`,
        },
        {
          text: "ใบแต่งทนาย",
          link: `/case/${this.state.id}/plaint/download/2`,
        },
        {
          text: "คำแถลงขอปิดหมาย",
          link: `/case/${this.state.id}/plaint/download/3`,
        },
        {
          text: "บัญชีพยาน",
          link: `/case/${this.state.id}/plaint/download/4`,
        },
        {
          text: "คำร้องขอขยายระยะเวลา",
          link: `/case/${this.state.id}/plaint/download/5`,
        },
        {
          text: "หมายเรียกจำเลย",
          link: `/case/${this.state.id}/plaint/download/6`,
        },
        {
          text: "ฟอร์มคำนวณทุนทรัพย์",
          link: `/case/${this.state.id}/plaint/download/7`,
        },
        {
          text: "คำร้องขอพิจารณาคดีทางระบบอิเล็กทรอนิกส์",
          link: `/case/${this.state.id}/plaint/download/8`,
        },
      ];
    },
    deptorAddress() {
      let str = "";
      if (this.model.deptor.address) {
        str = addressToString(this.model.deptor.address);
      }
      return str;
    },
    errorDeptorName() {
      let deptor = this.model.deptor;
      let prefix = getNamePrefixString(deptor);

      let isError = false;
      if (
        !(
          deptor &&
          deptor.name &&
          deptor.name.firstName &&
          deptor.name.lastName
        )
      ) {
        isError = true;
      }

      if (!prefix) {
        isError = true;
      }

      let message = "";
      if (isError) {
        message = "โปรดตรวจสอบข้อมูล";
      }

      return message;
    },
    fieldConfig() {
      return {
        nationality: {
          name: "nationality",
          text: "สัญชาติ",
          type: InputType.dropdown,
          data: {
            promise: dropdown.getNationalities(),
          },
          rule: {
            required: true,
          },
        },
        creditor: {
          name: "creditor",
          text: "ลูกค้า",
          type: InputType.dropdown,
          data: {
            promise: dropdown.getCustomers(),
          },
          rule: {
            required: true,
          },
        },
        idCardNo: {
          name: "idCardNo",
          text: "เลขที่บัตรประชาชน",
          type: InputType.text,
          rule: {
            required: true,
            max: 13,
          },
        },
        age: {
          name: "age",
          text: "อายุ",
          type: InputType.number,
          decimal: 0,
          rule: {
            required: true,
            max_value: 100,
            // number: true,
          },
        },
        noticeDate: {
          name: "noticeDate",
          text: "วันที่โนติส",
          type: InputType.datePicker,
        },
        deptSummaryDate: {
          name: "deptSummaryDate",
          text: "วันที่ตัดยอด",
          type: InputType.datePicker,
        },
        plaintDate: {
          name: "plaintDate",
          text: "วันที่ส่งฟ้อง",
          type: InputType.datePicker,
        },
        court: {
          name: "court",
          text: "ศาล",
          type: InputType.dropdown,
          data: {
            promise: dropdown.getCourts(),
          },
          rule: {
            required: true,
          },
        },
        lawyer: {
          name: "lawyer",
          text: "ทนาย",
          type: InputType.dropdown,
          data: {
            promise: this.isLawyer ? null : dropdown.getLawyers(),
          },
          rule: {
            required: true,
          },
        },
        noFongReason: {
          name: "noFongReason",
          text: "เหตุผลที่ยังไม่ฟ้อง",
          type: InputType.textArea,
          rule: {},
        },
      };
    },
  },
};
</script>

