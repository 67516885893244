import { constant } from 'lodash';
import { isManagement } from '../../js/authorization';
import { InputType, WrapType, ContractCondition, Customer } from '../../js/constants';
import * as http from './../../js/service';

export function getHeaders() {
    return [
        // line 1
        [
            {
                name: "agreementNo",
                text: "เลขที่สัญญาเงินกู้/บัตรเครดิต",
                type: InputType.text,
                disabledIf: (context) => {
                    if (isEditMode(context)) {
                        return true;
                    }

                    return false;
                }
            },
            {
                name: "contractDate",
                text: "วันที่ทำสัญญา",
                type: InputType.datePicker,
            },
            {
                type: InputType.offset,
            },
            {
                type: InputType.offset,
            },

        ],
        // line 2
        [
            {

                name: "creditLine",
                text: "วงเงิน",
                type: InputType.number,
                decimal: 2,
                disabledIf: (context) => {
                    if (isManagement()) {
                        return false;
                    }

                    if (isEditMode(context)) {
                        return true;
                    }

                    return true;
                }
            },
            {

                name: "principal",
                text: "ต้นเงิน",
                type: InputType.number,
                decimal: 2,
                disabledIf: (context) => {
                    if (isManagement()) {
                        return false;
                    }

                    if (isEditMode(context)) {
                        return true;
                    }

                    return true;
                }
            },
            {
                name: "interest",
                text: "ดอกเบี้ย",
                type: InputType.number,
                decimal: 2,
                disabledIf: (context) => {
                    if (isManagement()) {
                        return false;
                    }

                    if (isEditMode(context)) {
                        return true;
                    }

                    return true;
                }
            },
            {
                name: "penalty",
                text: "ค่าติดตามทวงถาม",
                type: InputType.number,
                decimal: 2,
                disabledIf: (context) => {
                    if (isManagement()) {
                        return false;
                    }

                    if (isEditMode(context)) {
                        return true;
                    }

                    return true;
                },
                showIf: (context) => {
                    const model = context.model;
                    if (model.customerId == Customer.RABBIT_ID) {
                        return false;
                    }
                    return true;
                }
            },
            {
                name: "hdCharge",
                text: "ค่าธรรมเนียมเบิกถอนเงิน",
                type: InputType.number,
                decimal: 2,
                disabledIf: (context) => {
                    if (isManagement()) {
                        return false;
                    }

                    if (isEditMode(context)) {
                        return true;
                    }

                    return true;
                },
                showIf: (context) => {
                    const model = context.model;
                    if (model.customerId == Customer.RABBIT_ID) {
                        return false;
                    }
                    return true;
                }
            },
            {
                name: "fee",
                text: "ค่าธรรมเนียมบัตร",
                type: InputType.number,
                decimal: 2,
                disabledIf: (context) => {
                    if (isManagement()) {
                        return false;
                    }

                    if (isEditMode(context)) {
                        return true;
                    }

                    return true;
                },
                showIf: (context) => {
                    const model = context.model;
                    if (model.customerId == Customer.RABBIT_ID) {
                        return false;
                    }
                    return true;
                }
            },
        ],

        // line 3
        [
            {
                name: "total",
                text: "ยอดหนี้รวมก่อนฟ้อง",
                type: InputType.number,
                decimal: 2,
                disabledIf: (context) => {
                    if (isEditMode(context)) {
                        return true;
                    }

                    return false;
                }
            },
            {
                name: "totalAmountAtPlaintDate",
                text: "ยอดหนี้รวม ณ วันฟ้อง",
                type: InputType.number,
                decimal: 2,
                disabledIf: (context) => {
                    if (isEditMode(context)) {
                        return true;
                    }

                    return false;
                }
            },
            {
                type: InputType.offset,
            },
            {
                type: InputType.offset,
            },
            {
                type: InputType.offset,
            },
            {
                type: InputType.offset,
            },

        ],
        [
            {
                type: InputType.section
            }
        ]
    ]
}


function newFields() {
    return [
        [
            {
                name: "interestRatePerYear",
                text: "ร้อยละ ดอกเบี้ย/ปี",
                type: InputType.number,
                decimal: 0,
                rule: {
                    required: true,
                    min_value: 0,
                    max_value: 100

                },
                suffixText: "%",
                showIf: interestRatePerYear
            },
            {
                name: "feeRatePerYear",
                text: "ร้อยละ ค่าธรรมเนียม/ปี",
                type: InputType.number,
                decimal: 2,
                rule: {
                    required: true
                },
                suffixText: "%",
                showIf: ratePerYearCondition
            },
            {
                name: "referenceAgreementNo",
                text: "อ้างอิงถึงสัญญาเดิมเลขที่",
                type: InputType.text,
                showIf: referenceContractCondition,
            },
            {
                name: "referenceContractDate",
                text: "วันที่สัญญาเดิม",
                type: InputType.datePicker,
                showIf: referenceContractCondition
            },
        ],
        [
            {
                name: "lastUsedDate",
                text: "วันที่จำเลยใช้ครั้งสุดท้าย",
                type: InputType.datePicker,
                showIf: (context) => {
                    const model = context.model;
                    if (model.contractType && model.contractType.id.equals(ContractCondition.AEONLoan_Versatile)) {
                        return false;
                    }

                    if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_Normal)) {
                        return false;
                    }

                    return lastPaidCondition(context)
                },
                column: 0
            },
            {
                name: "startPaidDate",
                text: "เริ่มชำระงวดแรกวันที่",
                type: InputType.datePicker,
                showIf: (context) => {
                    const model = context.model;
                    if (model.contractType && model.contractType.id.equals(ContractCondition.AEONLoan_Versatile)) {
                        return true;
                    }

                    if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_Normal)) {
                        return true;
                    }

                    return false
                },
                column: 0
            },
            {
                name: "lastUsedAmount",
                text: "จำนวน",
                type: InputType.number,
                decimal: 2,
                rule: {
                    // number: true,
                    required: true
                },
                showIf: (context) => {
                    const model = context.model;
                    if (model.contractType && model.contractType.id.equals(ContractCondition.AEONLoan_Versatile)) {
                        return false;
                    }

                    if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_Normal)) {
                        return false;
                    }

                    return lastPaidCondition(context)
                },
                column: 1
            },
            {
                name: "somePaidAmount",
                // text: "ชำระหนี้เป็นบางส่วน จำนวน",
                text: "รวมยอดชำระทั้งสิ้น จำนวน",
                type: InputType.number,
                decimal: 2,
                rule: {
                    // number: true,
                    required: true
                },
                showIf: (context) => {
                    const model = context.model;
                    if (model.contractType && model.contractType.id.equals(ContractCondition.AEONLoan_Versatile)) {
                        return true;
                    }

                    if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_Normal)) {
                        return true;
                    }

                    return false;
                },
                column: 1
            },
            {
                name: "lastPaidDate",
                text: "วันที่จำเลยชำระหนี้ครั้งสุดท้าย",
                type: InputType.datePicker,
                showIf: (context) => {
                    const model = context.model;
                    if (model.contractType && model.contractType.id.equals(ContractCondition.AEONLoan_Versatile)) {
                        return true;
                    }

                    if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_Normal)) {
                        return true;
                    }

                    return lastPaidCondition(context)
                }
            },
            {
                name: "lastPaidAmount",
                text: "ยอดชำระล่าสุด จำนวน",
                type: InputType.number,
                decimal: 2,
                rule: {
                    // number: true,
                    required: true
                },
                showIf: (context) => {
                    const model = context.model;
                    if (model.contractType && model.contractType.id.equals(ContractCondition.AEONLoan_Versatile)) {
                        return true;
                    }

                    if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_Normal)) {
                        return true;
                    }

                    return lastPaidCondition(context)
                }
            },
        ],
    ]

    function referenceContractCondition(context) {
        const model = context.model;
        if (model.condition && model.condition.id.equals(ContractCondition.AEONCard_Creditcard_AutoSwitch)) {
            return false;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONCard_Creditcard_AutoRenew)) {
            return true;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_YourCash_AutoRenew)) {
            return true;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_YourCash_AutoSwith)) {
            return true;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_YourCash_Normal)) {
            return false;
        }

        if (model.contractType && model.contractType.id.equals(ContractCondition.AEONLoan_Versatile)) {
            return false;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_Normal)) {
            return false;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_PranomNee)) {
            return false;
        }

        return false;
    }

    function interestRatePerYear(context) {
        const model = context.model;
        if (model.kyc) {
            return true
        }
        return ratePerYearCondition(context)
    }

    function ratePerYearCondition(context) {
        const model = context.model;
        if (model.contractType && model.contractType.id.equals(ContractCondition.AEONLoan_Versatile)) {
            return true;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_Normal)) {
            return true;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_PranomNee)) {
            return true;
        }
        return false;
    }

    function lastPaidCondition(context) {
        const model = context.model;
        if (model.condition && model.condition.id.equals(ContractCondition.AEONCard_Creditcard_Normal)) {
            return true;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONCard_Creditcard_AutoRenew)) {
            return true;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONCard_Creditcard_AutoSwitch)) {
            return true;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_YourCash_AutoRenew)) {
            return true;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_YourCash_AutoSwith)) {
            return true;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_YourCash_Normal)) {
            return true;
        }

        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_PranomNee)) {
            return true;
        }


        return false;
    }
}


function isEditMode(context) {
    const model = context.model;
    return model.id ? true : false;
}

function isPermission(context) {

    const model = context.model;
    return model.id ? true : false;
}


export const aeonForm = {
    header: getHeaders(),
    newFields: newFields()
}
