import { InputType } from './../../js/constants';


export default function () {
    return {
        url: "/case/contract/aira",
        rows: [
            // line 1
            [
                {

                    name: "agreementNo",
                    text: "Agreement No.",
                    type: InputType.text,
                    disabled: true
                },
                {

                    name: "cardNo",
                    text: "Card No.",
                    type: InputType.text,
                    disabled: true
                },
                {

                    name: "customerNo",
                    text: "Customer No.",
                    type: InputType.text,
                    disabled: true
                },
                {

                    name: "contractDate",
                    text: "Contract Date",
                    type: InputType.datePicker,
                    disabled: true
                },
                {

                    name: "outstandingBalanceDate",
                    text: "Outstanding Balance Date",
                    type: InputType.datePicker,
                    disabled: true
                }
            ],
            // line 2
            [
                {

                    name: "creditLine",
                    text: "Credit Line",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true,
                    // rule: {
                    //     number: true
                    // }
                },
                {

                    name: "principal",
                    text: "Principal",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true,
                    // rule: {
                    //     number: true
                    // }
                },
                {

                    name: "interest",
                    text: "Interest",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true,
                    // rule: {
                    //     number: true
                    // }
                },
                {
                    name: "fee",
                    text: "Fee",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true,
                    // rule: {
                    //     number: true
                    // }
                },
                {
                    name: "outstandingBalance",
                    text: "Outstanding Balance",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true,
                    // rule: {
                    //     number: true
                    // }
                },
            ],
            // line 3
            [
                {
                    name: "traceCharge",
                    text: "Trace Charge",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true,
                    // rule: {
                    //     number: true
                    // }
                },
                { type: InputType.offset },
                { type: InputType.offset },
                { type: InputType.offset },
                { type: InputType.offset },
            ],
            // line 4
            [
                {
                    name: "firstWithdrawDate",
                    text: "First Withdraw Date",
                    type: InputType.datePicker,
                },
                {
                    name: "firstWithdrawAmount",
                    text: "First Withdraw Amount",
                    type: InputType.number,
                    decimal: 2,
                    default: 0,
                    rule: {
                        required: true,
                        // number: true
                    }
                },
                {
                    name: "lastWithdrawDate",
                    text: "Last Withdraw Date",
                    type: InputType.datePicker,
                },
                {
                    name: "lastWithdrawAmount",
                    text: "Last Withdraw Amount",
                    type: InputType.number,
                    decimal: 2,
                    default: 0,
                    rule: {
                        required: true,
                        // number: true
                    }
                },

            ],
            // line 5
            [
                {
                    name: "lastPaidDate",
                    text: "Last Paid Date",
                    type: InputType.datePicker,
                },
                {
                    name: "lastPaidAmount",
                    text: "Last Paid Amount",
                    type: InputType.number,
                    decimal: 2,
                    default: 0,
                    // rule: {
                    //     number: true
                    // }
                },
                { type: InputType.offset },
                { type: InputType.offset },
            ],
            // line 6
            [
                {
                    name: "deptCalculateDate",
                    text: "Dept Calculate Date",
                    type: InputType.datePicker,
                },
                {
                    name: "deptSummaryDate",
                    text: "Dept Summary Date",
                    type: InputType.datePicker,
                    disabled: true
                },
                { type: InputType.offset },
                { type: InputType.offset },
            ],
            // line 7
            [
                {
                    name: "finalInterest",
                    text: "Final Interest",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true,
                    // rule: {
                    //     number: true
                    // }
                },
                {
                    name: "finalFee",
                    text: "Final Fee",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true,
                    // rule: {
                    //     number: true
                    // }
                },
                {
                    name: "finalTraceCharge",
                    text: "Final Trace Charge",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true,
                    // rule: {
                    //     number: true
                    // }
                },
                {
                    name: "finalTotal",
                    text: "Final Total",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true,
                    // rule: {
                    //     number: true
                    // }
                },
            ]

        ]
    };
}