<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="orange lighten-1 ml-5"
        v-bind="attrs"
        v-on="on"
      >Sale/Payment Inquiry</v-btn>

    </template>
    <template v-slot:default="dialog">
      <v-card>

        <v-toolbar
          color="primary"
          dark
        >เลือกประเภทเอกสารที่ต้องการอัพโหลด</v-toolbar>
        <v-card>
          <v-card-text>
            <v-radio-group v-model="typeRadioGroup">
              <v-radio
                key="sale"
                label="Sale Inquiry"
                value="sale"
              ></v-radio>
              <v-radio
                key="payment"
                label="Payment Inquiry"
                value="payment"
              ></v-radio>
            </v-radio-group>
            <custom-uploadfile
              ref="fileInput"
              @change="handleFile"
              :disabled="loading"
              class="mt-2"
            ></custom-uploadfile>
          </v-card-text>
        </v-card>
        <v-dialog
          v-model="dialogResult"
          max-width="500px"
        >
          <v-card>
            <v-card-title v-if="typeRadioGroup === 'sale'">ผลการอ่านไฟล์ Sale Inquiry</v-card-title>
            <v-card-title v-if="typeRadioGroup === 'payment'">ผลการอ่านไฟล์ Payment Inquiry</v-card-title>
            <!-- <v-card-text>
              <v-row>
                <v-col cols="5">
                  <v-label>Agreement/Card No.</v-label>
                </v-col>
                <v-col
                  class="text-right"
                  cols="5"
                >
                  <v-label v-if="typeRadioGroup === 'sale'">{{ returnObject.cardNumber }}</v-label>
                  <v-label v-if="typeRadioGroup === 'payment'">{{ returnObject.agreementNumber }}</v-label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-label>วันที่ใช้ล่าสุด</v-label>
                </v-col>
                <v-col
                  class="text-right"
                  cols="5"
                >
                  <v-label>{{ returnObject.summaryLatesDate }}</v-label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-label>ยอดใช้ล่าสุด</v-label>
                </v-col>
                <v-col
                  class="text-right"
                  cols="5"
                >
                  <v-label>{{ returnObject.summaryLatesAmount| currency(2) }}</v-label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-label>รวมยอดใช้ทั้งสิ้น</v-label>
                </v-col>
                <v-col
                  class="text-right"
                  cols="5"
                >
                  <v-label>{{ returnObject.summaryTotalAmount | currency(2)}}</v-label>
                </v-col>
              </v-row>
            </v-card-text> -->

            <v-card-text>
              <v-row>
                <v-col>
                  <v-label>Agreement/Card No.</v-label>
                </v-col>
                <v-col>
                  <v-label v-if="typeRadioGroup === 'sale'">{{ returnObject.cardNumber }}</v-label>
                  <v-label v-if="typeRadioGroup === 'payment'">{{ returnObject.agreementNumber }}</v-label>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-label>วันที่ใช้ล่าสุด</v-label>
                </v-col>
                <v-col>
                  <v-label>{{ returnObject.summaryLatesDate }}</v-label>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-label>ยอดใช้ล่าสุด</v-label>
                </v-col>
                <v-col>
                  <v-label>{{ returnObject.summaryLatesAmount| currency(2) }}</v-label>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-label>รวมยอดใช้ทั้งสิ้น</v-label>
                </v-col>
                <v-col>
                  <v-label>{{ returnObject.summaryTotalAmount | currency(2)}}</v-label>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="commitFile()"
              >
                บันทึกข้อมูลลงสัญญา
              </v-btn>
              <v-btn
                text
                @click="deleteUnusedFile()"
              > ยกเลิก </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card>
          <!-- existing docs -->
          <v-card-text>
            <v-simple-table
              id="uploadfile-table"
              dense
              class="mt-2"
            >
              <thead>
                <tr>
                  <th
                    class="text-center"
                    width="10"
                  >No.</th>
                  <th class="text-center">File Name</th>
                  <th class="text-center">Status</th>
                  <th
                    class="text-center"
                    width="10"
                    v-if="showRemove"
                  >Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>
                    <span v-if="item.fileUrl">
                      <a @click="download(item)">{{ item.fileName }}</a>
                    </span>
                    <span v-else>{{ item.fileName }}</span>
                  </td>
                  <td class="text-center">
                    {{ item.status }}
                  </td>
                  <td
                    class="text-center"
                    v-if="showRemove"
                  >
                    <v-icon
                      color="error"
                      @click="remove(item, index)"
                    >delete</v-icon>
                  </td>
                </tr>
                <tr v-show="items.length === 0">
                  <td
                    colspan="100%"
                    class="text-center"
                  >No Result</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>

        <v-card-actions class="justify-end mt-2">
          <v-btn
            text
            @click="dialog.value = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>


<script>
import CustomUploadfile from "../../components/custom/custom-uploadfile.vue";
import { getOnlyInquiryDocumentTypes } from "./../../js/dropdown-service";

export default {
  components: {
    CustomUploadfile,
  },
  data() {
    return {
      renders: [],
      items: [],
      dialogResult: false,
      loading: false,
      typeRadioGroup: "sale",
      fileName: "",
      showRemove: true,
      returnObject: {},
    };
  },
  created() {
    this.getMaster();
    this.getFiles();
  },
  methods: {
    openDialog(item) {
      this.state.selectedFileType = item;
      this.state.dialogUpload = true;
    },
    getMaster() {
      getOnlyInquiryDocumentTypes().then((res) => {
        this.renders = _.groupBy(res.items, "group");
      });
    },
    async handleFile(file) {
      this.$refs.fileInput.clearFile();
      if (!(file && file.length > 0)) {
        return;
      }

      let url = `/contractDataUpload/case/${this.$store.state.caseinfo.id}/${this.typeRadioGroup}/UploadInquiry`;

      this.fileName = file[0].name;

      var data = new FormData();
      data.append("file", file[0]);

      this.loading = true;
      this.$http
        .uploadFile(url, data)
        .then((res) => {
          this.returnObject = res;
          this.dialogResult = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async commitFile() {
      let url = `/contractDataUpload/case/${this.$store.state.caseinfo.id}/${this.typeRadioGroup}/SaveInquiry`;

      this.loading = true;
      this.$http
        .post(url, this.returnObject)
        .then((res) => {
          this.$refs.fileInput.clearFile();
          this.dialogResult = false;
          this.returnObject = {};

          this.getFiles();
          this.showAlertSuccess("อัพโหลดข้อมูลสำเร็จ");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async deleteUnusedFile() {
      let url = `/contractDataUpload/case/${this.$store.state.caseinfo.id}/DeleteAttachmentFile`;

      var data = new FormData();
      data.append("fileName", this.fileName);

      this.loading = true;
      this.$http
        .post(url, data)
        .then((res) => {
          this.dialogResult = false;
          this.returnObject = {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(item, index) {
      let _url = `/case/attachment/${item.id}`;

      this.$http.delete(_url).then((res) => {
        this.items.splice(index, 1);
      });
    },
    download(item) {
      let _url = `/case/attachment/${item.id}/download`;
      this.$http
        .downloadFile(_url)
        .then(() => {
          this.$emit("download-success");
        })
        .catch((er) => {
          this.$emit("download-error");
        });
    },
    downloadFile(item) {
      if (this.downloadApproach === downloadApproach.urlInItemPost) {
        if (item.fileUrl) {
          let url = `/case/attachment/${item.id}`;
          this.$http.downloadFile(url).then((res) => {
            this.showAlertSuccess("File has been downloaded successfully!");
          });
        }
      }

      if (this.downloadApproach === downloadApproach.newTab) {
        if (item.fileUrl) {
          window.open(item.fileUrl, "_blank");
        }
      }
    },
    getFiles() {
      let url = `/case/Attachment?caseId=${this.$store.state.caseinfo.id}&categoryId=d3ffb90b-04e8-450f-9a69-4cbcb4b8db30`;
      this.$http.get(url).then((res) => {
        this.items = res.items;
      });
    },
  },
  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
  },
};
</script>