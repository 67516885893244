<template>
  <div>
    <!-- upload transaction -->
    <v-row class="text-right">
      <v-col>
        <upload-transaction class="d-inline"></upload-transaction>
      </v-col>
    </v-row>

    <master-component
      ref="masterComponent"
      :config="config"
      :showSearch="false"
      :showAdd="false"
      :showRemove="hasAuthorize"
      :showSelect="true"
      :searchAfterSubmit="true"
      @click-edit="clickEdit"
      @results-search="setItems"
      :paramsId="null"
    >
      <template v-slot:table-button-add>
        <v-btn
          color="primary"
          @click="clickAddCardContract"
        >Add Card Contract</v-btn>
        <v-btn
          class="ml-1"
          color="primary"
          @click="clickAddLoanContract"
        >Add Loan Contract</v-btn>
      </template>
    </master-component>
  </div>
</template>


<script>
import uploadTransaction from "./upload-transaction";
import * as dropdown from "./../../js/dropdown-service";
import {
  UserRole,
  InputType,
  ContractType,
  ContractCondition,
  DialogSize,
} from "./../../js/constants";
import { getUI } from "./../contracts/contract-ui-provider";
import { fakeAbbr, getFakeUrl } from "./../../_helpers/fake-url";
import { Customer } from "./../../js/constants";
import { openNewTabWithDelay } from "./../../js/custom";
import { mapGetters } from "vuex";

export default {
  components: {
    "upload-transaction": uploadTransaction,
  },
  data() {
    return {
      canMoveContractButton: null,
      selectedItems: [],
      config: {},
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    setItems(items) {
      this.$store.state.caseinfo.contracts = items;
    },
    search() {
      this.$refs.masterComponent.search();
    },
    initialConfig() {
      this.config = {
        name: "contract",
        shortName: "Contract",
        search: { rows: [] },
        form: {},
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
    clickEdit(item, index) {
      let form = getUI(item.contractTypeId);
      form.appendModel = {
        contractTypeId: item.contractTypeId,
      };
      this.changeForm(form);
    },
    changeForm(newForm) {
      newForm.url = newForm.url.replace("{caseId}", this.state.id);
      newForm.conditionCanSubmit = () => {
        if (this.$store.getters["caseinfo/anotherConditionDisabledEdit"]) {
          return false;
        }
        return true;
      };
      newForm.size = DialogSize.fullScreen;
      this.config.form = newForm;
    },
    openDialog() {
      this.$refs.masterComponent.showFormDialog();
    },
    clickAddCardContract() {
      this.$refs.masterComponent.setId(null);
      let form = getUI(ContractType.AEON_CARD);
      this.changeForm(_.cloneDeep(form));
      this.openDialog();
    },
    clickAddLoanContract() {
      this.$refs.masterComponent.setId(null);
      let form = getUI(ContractType.AEON_LOAN);
      this.changeForm(_.cloneDeep(form));
      this.openDialog();
    },
    moveContractToAnother() {
      this.selectedItems = this.$refs.masterComponent.getSelectedItems();
      this.canMoveContractButton =
        this.$refs.masterComponent.$refs.masterTable.indeterminate;
      if (!this.canMoveContractButton) {
        this.showAlertError("ไม่สามารถย้ายสัญญาทั้งหมดได้");
        return;
      }

      if (
        confirm(
          `Are You sure to move ${this.selectedItems.length} contracts to create another one?`
        )
      ) {
        let url = `/case/${this.state.id}/moveToNewCase`;
        let payload = {
          contractIds: this.selectedItems.map((x) => x.id),
        };

        this.$http.post(url, payload).then((res) => {
          this.showAlertSuccess();
          this.search();

          let routeData = this.$router.resolve({
            name: "case-details",
            params: {
              id: res.newCaseId,
            },
          });
          openNewTabWithDelay({ url: routeData.href, delay: 3000 });
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      anotherConditionDisabledEdit: "caseinfo/anotherConditionDisabledEdit",
    }),
    state() {
      return this.$store.state.caseinfo;
    },
    hasAuthorize() {
      let permission = [
        UserRole.ADMIN,
        UserRole.MANAGEMENT,
        UserRole.BACK_OFFICE,
      ];

      return (
        this.hasPermission(permission) && !this.anotherConditionDisabledEdit
      );
    },
    bulkAction() {
      if (this.anotherConditionDisabledEdit) {
        return [];
      }

      return [
        {
          text: "Move contract to another",
          condition: ({ selected }) => {
            return true;
          },
          func: () => {
            this.moveContractToAnother();
          },
          permission: [
            UserRole.ADMIN,
            UserRole.MANAGEMENT,
            UserRole.BACK_OFFICE,
          ],
        },
      ];
    },
    urlSection() {
      let searchUrl = `/case/${this.state.id}/contract/search`;
      let getUrl = "/case/contract";
      let removeUrl = "/case/contract";

      return {
        search: searchUrl,
        get: getUrl,
        remove: removeUrl,
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
        edit: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
        remove: [UserRole.ADMIN],
      };
    },
    tableSection() {
      return {
        title: "Contracts",
        bulkAction: this.bulkAction,
        columns: [
          {
            name: "contractDateString",
            text: "วันที่ทำสัญญา",
          },
          {
            name: "accNo",
            text: "Contract No",
          },
          {
            name: "contractTypeName",
            text: "Contract Type",
          },
          {
            name: "total",
            text: "ยอดหนี้รวมก่อนฟ้อง",
            class: "text-right",
            numeric: 2,
          },
          {
            name: "totalAmountAtPlaintDate",
            text: "ยอดหนี้รวม ณ วันฟ้อง",
            class: "text-right",
            numeric: 2,
          },
        ],
      };
    },
  },
};
</script>
