<template>
  <div>
    <!-- todo remove afterwards -->

    <!-- <v-row v-if="!isServerProduction">
      <v-expansion-panels
        mandatory
        v-if="state.useLocalStorage"
      >
        <v-expansion-panel
          v-for="(item, i) in 1"
          :key="i"
        >
          <v-expansion-panel-header>Control Status</v-expansion-panel-header>
          <v-expansion-panel-content>
            <control-status
              :case="state.model.statusCase"
              :sue="state.model.statusSue"
              :implement="true"
              @status-changed="statusChanged"
            ></control-status>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-col>
        <v-btn
          text
          class="info mr-10"
          @click="clearStorage"
        >Clear storage</v-btn>

        <v-btn
          text
          @click="setRole(UserRole.LAWYER)"
        >Lawyer</v-btn>
        <v-btn
          text
          @click="setRole(UserRole.BACK_OFFICE)"
        >Back Office</v-btn>
      </v-col>
    </v-row> -->

    <!-- todo remove afterwards -->
    <v-row>
      <v-col>
        <v-card color="basil">
          <v-card-title class="text-center justify-start py-1 px-2">
            <v-col class="text-left pl-0">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-3 font-weight-bold">
                    {{
                    state.model.caseNumber
                    }}

                    <v-chip
                      class="ml-1 mt-n1"
                      small
                    >
                      {{state.model.lot}}
                    </v-chip>

                    <v-chip
                      color="primary"
                      small
                      class="ml-1 mt-n1"
                      v-if="state.model.branch"
                    >
                      {{state.model.branch.text}}
                    </v-chip>
                    <v-icon
                      class="ml-1 mt-n1"
                      v-if="state.model.hasLeasingContract"
                    >mdi-car</v-icon>

                    <span
                      class="mx-2"
                      style="font-size:13px; position: relative; top: -3px"
                    >{{state.model.blackNumber}}</span>
                    <span
                      class="mx-2 red--text"
                      style="font-size:13px; position: relative; top: -3px"
                    >{{state.model.redNumber}}</span>

                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                    $store.getters["caseinfo/deptorName"]
                    }}

                  </v-list-item-subtitle>

                </v-list-item-content>
              </v-list-item>

            </v-col>

            <v-col class="text-right">
              <div class="mb-1">
                <v-chip
                  class="py-2 pl-0 pr-2"
                  color="orange"
                  text-color="white"
                >
                  <v-icon
                    right
                    class="mr-2"
                  >stars</v-icon>
                  {{ $store.getters["caseinfo/statusCaseText"] }}
                </v-chip>
              </div>
              <div class="mb-1">
                <v-chip
                  class="py-2 pl-0 pr-2"
                  color="teal"
                  text-color="white"
                  v-if="$store.getters['caseinfo/statusSueText']"
                >
                  <v-icon
                    right
                    class="mr-2"
                  >account_balance</v-icon>
                  {{ $store.getters["caseinfo/statusSueText"] }}
                </v-chip>
              </div>

              <div
                v-if="$store.getters['caseinfo/statusNCBText']"
                class="mb-1"
              >
                <v-chip
                  class="ml-1 py-2 px-2"
                  color="teal lighten-2"
                  text-color="white"
                >

                  NCB : {{ $store.getters["caseinfo/statusNCBText"] }}
                </v-chip>
              </div>
              <div
                v-if="state.model.pauseFlag"
                class="mb-1"
              >
                <span>
                  <v-icon class="diso">lock</v-icon> {{ $store.getters["caseinfo/pauseReason"] }}
                  <pre>{{state.model.pauseRemarkText}}</pre>

                </span>
              </div>
              <div
                v-if="state.model.isInSAM"
                class="mb-1"
              >
                <span>
                  <v-icon>lock</v-icon> เข้า SAM หลังฟ้อง
                </span>
              </div>
              <div
                v-if="state.model.isPidnudChamRa"
                class="mb-1"
              >
                <span
                  class="red--text"
                  style="font-size:13px"
                >
                  ผิดนัดชำระ ชำระเข้ามาแล้วทั้งสิ้น &nbsp;{{ state.model.totalPaidPraneePranom  | currency(2) }} บาท แจ้งทนายเมื่อวันที่ {{ state.model.jangLawyerPidnudDate }} ชำระล่าสุดวันที่ {{ state.model.lastPaymentDatePraneepranom }}
                </span>
              </div>
              <div
                v-if="state.model.isMigration"
                class="mb-1"
              >
                <v-icon color="success">mdi-microsoft-excel</v-icon> <span
                  v-html="state.model.migrationStatus"
                  style="font-size:15px"
                ></span>
              </div>
            </v-col>
          </v-card-title>

          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            @change="tabChanged"
          >
            <v-tab
              v-for="item in items"
              :key="item"
            >
              <h2>{{ item }}</h2>

              <v-badge
                v-if="item === 'กิจกรรมทนาย' && conditionShowBadgeToLawyerTab"
                color="deep-purple accent-4"
              >
                <template v-slot:badge>
                  <v-icon class="white--text">mdi-bell</v-icon>
                </template>
              </v-badge>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-container>
                <case-general-tab
                  ref="generalTab"
                  @status-changed="statusChanged"
                ></case-general-tab>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container>
                <case-contract-tab
                  :caseId="id"
                  ref="contractTab"
                ></case-contract-tab>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container>
                <relative-tab :caseId="id"></relative-tab>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <!-- <v-container> -->
              <case-lawyer-action-tab></case-lawyer-action-tab>
              <!-- </v-container> -->
            </v-tab-item>
          </v-tabs-items>

          <v-row class="mb-3">
            <v-btn
              color="error"
              class="ml-5"
              @click="deleteCase()"
              v-if="showDeleteCase"
              small
            >
              <v-icon>mdi-delete</v-icon>ลบเคสนี้
            </v-btn>

            <v-btn
              color="warning"
              class="ml-5"
              @click="updateStatusSue()"
              v-if="$store.getters['pageinfo/isSupportMode']"
              small
            >
              <v-icon>mdi-refresh</v-icon>อัพเดทสถานะคดี
            </v-btn>
          </v-row>

        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CaseGeneralTab from "./case-general-tab";
import CaseContractTab from "./case-contract-tab";
import ControlStatus from "./control-case-status";
import RelativeTab from "./relative-tab";
import CaseLawyerActionTab from "./case-lawyer-action-tab";
import * as dropdown from "./../../js/dropdown-service";
import { StatusSue, UserRole } from "./../../js/constants";

import { getToken, setStorageToken } from "./../../js/authorization";
import manageStorage from "./../../js/manage-localstorage";
import { mapGetters } from "vuex";

import db from "./../../_helpers/fake-db";

export default {
  props: {
    id: {
      type: String,
      require: true,
    },
    currentTab: String,
  },
  components: {
    "case-general-tab": CaseGeneralTab,
    "case-contract-tab": CaseContractTab,
    "relative-tab": RelativeTab,
    "control-status": ControlStatus,
    "case-lawyer-action-tab": CaseLawyerActionTab,
  },
  data() {
    return {
      tab: null,
      items: [],
      UserRole: UserRole,
    };
  },
  watch: {
    title: {
      handler(newVal) {
        document.title = newVal;
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.state.id = this.id;
    this.initTabName();

    // this.$store.dispatch("caseinfo/initialUseLocalStorage");
    // this.$store.dispatch("caseinfo/getCase");
    this.$store.dispatch("caseinfo/initData");
    this.setTabAndUrl();
  },
  methods: {
    deleteCase() {
      let url = `/support/DeleteCase/${this.id}`;

      if (
        confirm(
          "คุณแน่ใจหรือไม่ที่จะลบข้อมูลของเคสนี้ทั้งหมด โปรแกรมจะไม่สามารถกู้คืนข้อมูลนี้ได้"
        )
      ) {
        this.$http.delete(url).then((res) => {
          this.$router.go(this.$router.currentRoute);
        });
      }
    },
    updateStatusSue() {
      let url = `/support/UpdateStatusSue?caseId=${this.id}`;

      if (confirm("คุณแน่ใจหรือไม่ที่จะอัพเดทสถานะคดีให้เป็นล่าสุด")) {
        this.$http.get(url).then((res) => {
          this.showAlertSuccess();
        });
      }
    },
    setTabAndUrl() {
      let targetTab = null;

      if (!_.isNil(this.currentTab)) {
        targetTab = Number(this.currentTab);
      } else {
        if ([UserRole.LAWYER].includes(this.currentRole)) {
          targetTab = 3;
        }

        this.changeUrl(targetTab);
      }

      this.tab = targetTab;
    },
    changeUrl(tab) {
      if (_.isNil(tab)) {
        return;
      }

      this.$router.push({
        name: this.$route.name,
        params: {
          id: this.id,
          currentTab: tab.toString(),
        },
      });
    },
    setRole(role) {
      let obj = getToken();
      obj.role = role;

      setStorageToken(JSON.stringify(obj));
      if (this.state.useLocalStorage) {
        this.saveStorage();
      }
      location.reload();
    },
    saveStorage() {
      this.$store.dispatch("caseinfo/saveStorage");
    },
    clearStorage() {
      this.$store.dispatch("caseinfo/clearStorage");
    },
    initTabName() {
      this.items.push("ทั่วไป");
      this.items.push("สัญญา");
      this.items.push("ทายาท");
      this.items.push("กิจกรรมทนาย");
    },
    tabChanged(tabNumber) {
      if (tabNumber === 1) {
        //always refresh contract tab
        if (this.$refs.contractTab) {
          // this.$refs.contractTab.search();
        }
      }
      this.changeUrl(tabNumber);
    },
    statusChanged(val) {
      const { statusCase, statusSue } = val;
      this.$store.commit("caseinfo/setStatusSue", statusSue);
      this.$store.commit("caseinfo/setStatusCase", statusCase);
    },
  },
  computed: {
    title() {
      return `[${process.env.VUE_APP_SERVER[0]}] ${this.$store.getters["caseinfo/deptorName"]}`;
    },
    state() {
      return this.$store.state.caseinfo;
    },
    conditionShowBadgeToLawyerTab() {
      let condition1 = this.conditionStatusCaseNotificationToLawyer;
      let condition2 =
        this.countPaymentBadgeToShowBackOffice +
          this.countReadyToRequestPayment >
        0;
      return condition1 || condition2;
    },
    showDeleteCase() {
      let auth = getToken();
      let condition1 = this.id ? true : false;
      let condition2 = ["kawin", "udomjit", "Yupha"].some(
        (x) => x.toLowerCase() == auth.username.toLowerCase()
      );

      let result = condition1 && condition2;
      return result;
    },
    ...mapGetters({
      conditionStatusCaseNotificationToLawyer:
        "caseinfo/conditionStatusCaseNotificationToLawyer",
      countPaymentBadgeToShowBackOffice:
        "caseinfo/countPaymentBadgeToShowBackOffice",
      countReadyToRequestPayment: "caseinfo/countReadyToRequestPayment",
    }),
  },
};
</script>
