<template>
  <v-card>
    <ValidationObserver
      ref="observer"
      v-slot="{ valid }"
    >
      <v-toolbar color="primary">
        <v-btn
          icon
          @click="hideDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>ที่อยู่ลูกหนี้</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-toolbar-items>
          <v-btn
            text
            @click="submit"
            :disabled="!(valid)"
          >Submit</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <show-model :text="payload"></show-model>
        <address-block v-model="innerValue"></address-block>
      </v-card-text>
    </ValidationObserver>
  </v-card>
</template>


<script>
import AddressBlock from "./../master-render/address-block";
import { isEmpty, addressStructure } from "./../../js/custom";

import { fakeAbbr, getFakeUrl } from "./../../_helpers/fake-url";

export default {
  props: {
    value: Object,
    caseId: {
      type: String,
      required: true,
    },
  },
  components: {
    "address-block": AddressBlock,
  },
  data() {
    return {
      innerValue: {},
    };
  },
  watch: {
    stateDialog: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.innerValue = _.cloneDeep(this.value || addressStructure());
          });
        }
      },
    },
  },

  methods: {
    // getProvinces,
    hideDialog() {
      this.state.dialogAddr = false;
    },
    submit() {
      let url = `/case/deptorAddr`;

      this.$http.put(url, this.payload).then((res) => {
        //this.items = res.items;
        this.$emit("changed-deptor-addr", this.innerValue);
        this.hideDialog();
        this.showAlertSuccess();
      });
    },
  },
  computed: {
    payload() {
      return {
        caseId: this.caseId,
        address: this.innerValue,
      };
    },
    state() {
      return this.$store.state.caseinfo;
    },
    stateDialog() {
      return this.state.dialogAddr;
    },
  },
};
</script>
