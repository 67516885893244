<template>
  <v-expansion-panel @click="clickPanelHeader">
    <div class="text-right">
      <v-menu>
        <template
          v-slot:activator="{ on }"
          v-if="showSettingButton"
        >
          <v-icon
            small
            left
            v-on="on"
          >mdi-cog</v-icon>
        </template>

        <v-list dense>
          <v-list-item
            v-for="(item,index) in settingItems"
            :key="index"
            @click="item.func()"
          >
            <v-icon left>{{item.icon}}</v-icon>
            {{item.text}}
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-expansion-panel-header>
      <v-row>
        <v-col cols="7">
          <span v-html="titleText"></span>
        </v-col>
        <custom-copy-value
          :item="item"
          :inline="true"
        ></custom-copy-value>

        <v-col class="text-right">
          <span class="mr-2">
            <v-icon
              color="red"
              small
              v-show="showIconNotFinish"
            >pause</v-icon>
            {{postByText}} &nbsp;{{authorText}}
          </span>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="grey lighten-5">
      <custom-secure-block
        ref="secureBlock"
        :rows="rows"
        v-model="item.details"
        :id="item.id"
        @click-submit="submitEdit"
        :canEdit="canEdit"
      ></custom-secure-block>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { flagDisabledAllFields } from "./../../js/custom";
import {
  StatusCase,
  StatusSue,
  UserRole,
  RevertType,
} from "./../../js/constants";

import { getFakeUrl, fakeAbbr } from "../../_helpers/fake-url";
import { item_confirmCloseCasewithOokmaiTang } from "./update-status/update-status-service";

export default {
  props: {
    rows: {
      type: Array,
      required: true,
    },
    value: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickPanelHeader() {
      this.$emit("clickPanelHeader");
    },
    cancelEditMode() {
      this.$refs.secureBlock.cancelEditMode();
    },
    submitEdit() {
      this.$refs.secureBlock.showWaitingCursor();

      let url = `/case/${this.state.id}/statusSue`;
      // if (this.state.useLocalStorage) {
      //   url = getFakeUrl(fakeAbbr.updateStatus.editStatus) + url;
      // }

      let payload = _.cloneDeep(this.item);
      this.$http
        .put(url, payload)
        .then((res) => {
          this.cancelEditMode();
          this.showAlertSuccess();
          this.$emit("submitted", res);
        })
        .finally(() => {
          this.$refs.secureBlock.hideWaitingCursor();
        });
    },
  },
  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
    item: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    showSettingButton() {
      return this.settingItems.some((x) => x.condition());
    },
    settingItems() {
      return [
        {
          text: "ลบรายการนี้",
          icon: "mdi-trash",
          condition: () => {
            return this.value.canRevert;
          },
          func: () => {
            if (confirm("คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้ ?")) {
              let url = `/case/revertNgodmaitang?logId=${this.value.id}`;

              // let payload = {
              //   data: [this.value.id],
              //   caseId: this.state.id,
              //   type: RevertType["ลบสถานะคดี"].id,
              // };
              this.$http.post(url).then((res) => {
                this.$emit("submitted", res);
              });
            }
          },
        },
      ];
    },
    showIconNotFinish() {
      if (this.value.details.hasOwnProperty("completedDocument")) {
        if (this.value.details.completedDocument === false) {
          return true;
        }
      }
      return false;
    },
    postByText() {
      let str = "";
      if (this.item.isEdited) {
        str = `แก้ไขเมื่อ ${this.item.editedDate} น.`;
      } else {
        str = `เมื่อ ${this.item.postedDate} น.`;
      }

      return str;
    },
    authorText() {
      let str = "";
      if (this.item.isEdited) {
        str = `โดย ${this.item.editedBy.text}`;
      } else {
        str = `โดย ${this.item.postedBy.text}`;
      }

      return str;
    },
    titleText() {
      let str = "";
      // if (this.item.statusSueBeforePost) {
      //   str += this.item.statusSueBeforePost.text;
      // }

      if (this.item.option) {
        str += `<b> : <u>${this.item.option.text}</u></b>`;
      }

      // if (this.item.statusSueAfterPost) {
      //   str += ` > ${this.item.statusSueAfterPost.text}`;
      // }
      return str;
    },
  },
};
</script>



