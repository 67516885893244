<template>
  <div
    v-if="conditionShow"
    :key="count"
  >
    <render-input
      v-if="position == 'bottom'"
      :input="{type:'section', text:'Danger Zone'}"
    ></render-input>

    <case-action
      v-for="(item) in items"
      :key="item.title"
      :item="item"
    ></case-action>
  </div>
</template>

<script>
import { StatusCase, StatusSue, UserRole } from "./../../js/constants";
import * as actions from "./update-status/update-status-service";
import CaseAction from "./case-action";

export default {
  components: {
    CaseAction,
  },
  props: {
    position: {
      type: String,
    },
  },
  data() {
    return {
      count: 0,
    };
  },
  watch: {
    statusCase: {
      handler(newVal) {
        this.count++;
      },
      immediate: true,
      deep: true,
    },
    statusSue: {
      handler(newVal) {
        this.count++;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {},

  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
    statusSue() {
      return this.state.model.statusSue;
    },
    statusCase() {
      return this.state.model.statusCase;
    },
    items() {
      let filtered = _.toArray(actions).filter(
        (x) => x.position === this.position && x.condition()
      );

      if (this.state.model.pauseFlag) {
        filtered = filtered.filter(
          (x) => x.name == actions.item_unlockCase.name
        );
      }

      return filtered;
    },
    conditionShow() {
      let resultAuthorize = this.items.some((x) => {
        return this.hasPermission(x.permission) === true;
      });

      if (!resultAuthorize) return false;

      return true;
    },
  },
};
</script>