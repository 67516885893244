<template>
  <div v-if="config.showPanel">
    <show-model :text="config"></show-model>
    <v-card>
      <div class="text-right">
        <v-menu>
          <template
            v-slot:activator="{ on }"
            v-if="showSettingButton"
          >
            <v-icon
              small
              left
              v-on="on"
            >build</v-icon>
          </template>

          <v-list dense>
            <v-list-item
              v-for="(item,index) in settingItems"
              :key="index"
              @click="item.func()"
            >
              <v-icon left>{{item.icon}}</v-icon>
              {{item.text}}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-card-text>
        <ValidationObserver
          ref="observer"
          v-slot="{ valid }"
        >

          <h2 class="d-inline-block teal--text">{{ config.text }}</h2>
          <v-chip
            class="ml-2"
            style="margin-top:-3px"
            :class="conditionColor"
          >{{ statusText }}</v-chip>
          <span class="ml-1 caption">
            {{
            config && config.latestDate ? `${config.latestDate}` : ""
            }}
          </span>

          <v-btn
            text
            color="primary"
            class="mt-n2"
            v-if="conditionShowApprove"
            @click="approve"
          >อนุมัติ</v-btn>

          <section>
            <v-row>
              <v-col :offset="1">
                <v-row class="mb-3 info--text">
                  <v-col
                    :cols="4"
                    class="subtitle-1"
                  >รายการ</v-col>
                  <v-col
                    :cols="3"
                    class="subtitle-1"
                  >จำนวนเงิน</v-col>
                </v-row>
                <v-row
                  v-for="(item, index) in config.list"
                  :key="index"
                >
                  <v-col :cols="4">{{ item.name }}</v-col>
                  <v-col :cols="3">
                    <render-input
                      :input="fieldConfig.amount"
                      :disabled="!item.allowEditAmount || loading"
                      v-model="item.amount"
                    ></render-input>
                  </v-col>
                </v-row>
                <v-row class="info--text">
                  <v-col :cols="4">
                    <p class="title">รวม</p>
                  </v-col>
                  <v-col
                    :cols="3"
                    class="text-right"
                  >
                    <p class="subtitle-1">{{ total | currency(2) }}</p>
                  </v-col>
                </v-row>

                <v-row v-if="loading">
                  <v-col offset-md="4">
                    <span>Loading...</span>
                  </v-col>
                </v-row>

                <v-row v-else>
                  <v-col offset-md="4">
                    <v-btn
                      color="error"
                      class="white--text"
                      v-if="conditionShowCancel"
                      @click="cancel"
                    >
                      {{config.textCancelRequest}}
                    </v-btn>

                    <v-btn
                      color="info"
                      class="white--text"
                      v-if="conditionShowSubmit"
                      @click="submit"
                      :disabled="!valid"
                    >
                      {{config.textRequest}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </section>
        </ValidationObserver>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { createDynamicModel, sumList } from "./../../js/custom";
import { InputType, StatusPayment, RevertType } from "../../js/constants";

import { getFakeUrl, fakeAbbr } from "../../_helpers/fake-url";

export default {
  props: {
    config: Object,
  },
  data() {
    return {
      other: {},
      otherChecked: true,
      loading: false,
    };
  },

  methods: {
    submit() {
      let url = `/case/${this.state.id}/advance/${this.config.paymentPartId}/submit`;

      let payload = _.cloneDeep(this.config);
      payload.list = payload.list.filter((x) => x.allowEditAmount);
      this.loading = true;

      this.$http
        .post(url, payload)
        .then((res) => {
          this.showAlertSuccess("กำลังยื่นขอเบิกเงิน");
          // this.$store.commit("caseinfo/updatePayment", res);
          this.$store.dispatch("caseinfo/getCurrentPaymentStatus");
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    approve() {
      let url = `/case/${this.state.id}/payment/part/${this.config.paymentPartId}/approve`;

      this.$http.post(url).then((res) => {
        this.showAlertSuccess();
        this.$store.dispatch("caseinfo/getCurrentPaymentStatus");
        // this.$store.commit("caseinfo/updatePayment", res);
      });
    },
    cancel() {
      let url = `/case/${this.state.id}/advance/${this.config.paymentPartId}/cancel`;
      this.loading = true;

      this.$http
        .post(url)
        .then((res) => {
          this.showAlertSuccess("ยกเลิกการเบิกเงินเรียบร้อยแล้ว");

          this.$store.dispatch("caseinfo/getCurrentPaymentStatus");
          // this.$store.commit("caseinfo/updatePayment", res);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
  },

  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
    statusText() {
      return _.get(this.config, "status.text");
    },
    total() {
      let result = sumList(this.config.list, "amount");
      return result;
    },
    showSettingButton() {
      return this.settingItems.some((x) => x.condition());
    },
    settingItems() {
      return [
        // {
        //   text: "ยกเลิกการอนุมัติ",
        //   icon: "block",
        //   condition: () => {
        //     return this.config.showCancelApprove;
        //   },
        //   func: () => {
        //     if (confirm("คุณต้องการยกเลิกการอนุมัติรายการนี้ใช่หรือไม่")) {
        //       let url = `/case/revert`;
        //       let payload = {
        //         data: [this.config.paymentPartId],
        //         caseId: this.state.id,
        //         type: RevertType["ยกเลิกอนุมัติ"].id,
        //       };
        //       this.$http.post(url, payload).then((res) => {
        //         this.$store.commit("caseinfo/updatePayment", res);
        //       });
        //     }
        //   },
        // },
      ];
    },
    status() {
      if (this.config) {
        let { status } = this.config;
        return status;
      }
      return null;
    },
    conditionColor() {
      if (!this.status) {
        return "cyan white--text";
      }

      switch (this.status.id) {
        case StatusPayment["รออนุมัติ"].id:
          return "orange white--text";
        case StatusPayment["อนุมัติแล้ว"].id:
          return "teal white--text";
        case StatusPayment["จ่ายเงินแล้ว"].id:
          return "success white--text";

        default:
          return "Secondary";
      }
    },
    conditionShowApprove() {
      return this.config.showApproveButton;
    },
    conditionShowCancel() {
      return this.config.showCancelRequestButton;
    },
    conditionShowSubmit() {
      return this.config.showRequestButton;
    },
    fieldConfig() {
      return {
        checklist: {
          name: "checklist",
          text: "",
          type: InputType.checkBox,
        },
        name: {
          name: "name",
          text: "รายละเอียด",
          type: InputType.text,
        },
        amount: {
          name: "amount",
          text: "จำนวนเงิน",
          type: InputType.number,
          decimal: 2,
          rule: {
            min_value: 1,
          },
        },
      };
    },
  },
};
</script>
