<template>
  <div>
    <!-- read mode -->
    <div v-if="readMode">
      <slot name="show-text" :text="showText">
        <label>{{showText}}</label>
      </slot>

      <button-inline v-if="canEdit" @onClick="clickEdit"></button-inline>
    </div>

    <!-- edit mode -->
    <div v-else>
      <ValidationObserver ref="observer" v-slot="{ valid }">
        <render-input v-model="innerValue" :input="input"></render-input>

        <v-btn text icon color="primary" @click="clickSave" :disabled="!valid" class="text-center">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn text icon @click="reset()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { InputType } from "./../../js/constants";

export default {
  props: {
    value: [String, Number, Object, Array, Boolean],
    input: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      readMode: true,
      innerValue: null,
      inputType: InputType
    };
  },
  computed: {
    showValue() {
      let val = null;
      if (this.input.type === this.inputType.dropdown) {
        val = this.value || {};
      } else {
        if (!_.isNil(this.value)) {
          val = this.value;
        } else {
          val = null;
        }
      }

      return val;
    },
    showText() {
      let text = null;
      if (!_.isNil(this.value)) {
        if (this.input.type === InputType.dropdown) {
          text = this.value.text;
        } else {
          text = this.value;
        }
      }
      return text;
    }
  },
  methods: {
    clickSave() {
      this.$emit("clickedSave", this.innerValue);
    },
    clickEdit() {
      this.innerValue = _.cloneDeep(this.value);
      this.readMode = false;
    },
    reset() {
      this.readMode = true;
    },
    close() {
      this.readMode = true;
    }
  }
};
</script>