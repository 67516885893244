<template>
  <div style="min-height:500px">
    <div v-if="items.some((item) => item.showPanel)">
      <template v-for="(item,index) in items">
        <custom-copy-value
          :item="item"
          :key="index"
        ></custom-copy-value>

        <v-card
          class="mb-3"
          v-if="$store.getters['pageinfo/isSupportMode']"
          :key="item.id+item.id"
        >
          <v-card-title>Support Zone</v-card-title>
          <v-btn
            color="error"
            style="margin-top: -10px"
            @click="deletePayment(item)"
          >
            ลบ

          </v-btn>

        </v-card>

        <LawyerPaymentAdvancePanel
          v-if="item.paymentTypeId === paymentType['advance'].id"
          :key="item.id"
          class="mb-5"
          :config="item"
        ></LawyerPaymentAdvancePanel>
        <LawyerPaymentVichacheepPanel
          v-else-if="item.paymentTypeId === paymentType['ค่าวิชาชีพ'].id"
          :key="item.id"
          class="mb-5"
          :config="item"
        ></LawyerPaymentVichacheepPanel>
      </template>

    </div>

    <div v-else>
      <v-alert
        type="info"
        outlined
        class="my-10 elevation-7"
      >ยังไม่มีรายการที่สามารถเบิกเงินได้สำหรับเคสนี้</v-alert>
    </div>
  </div>
</template>

<script>
import LawyerPaymentVichacheepPanel from "./lawyer-payment-vichacheep-panel";
import LawyerPaymentAdvancePanel from "./lawyer-payment-advance-panel";

import {
  StatusPayment,
  StatusCase,
  StatusSue,
  UserRole,
  PaymentPart,
  PaymentType,
  InputType,
} from "./../../js/constants";

export default {
  components: {
    LawyerPaymentVichacheepPanel: LawyerPaymentVichacheepPanel,
    LawyerPaymentAdvancePanel: LawyerPaymentAdvancePanel,
  },
  data() {
    return {
      paymentPart: PaymentPart,
      paymentType: PaymentType,
    };
  },
  methods: {
    deletePayment(item) {
      let url = `/support/DeletePayment/case/${item.caseId}/part/${item.paymentPartId}`;
      this.$http.delete(url).then((res) => {
        this.showAlertSuccess("ลบข้อมูลเรียบร้อย");
        this.$store.dispatch("caseinfo/getCurrentPaymentStatus");
      });
    },
  },
  computed: {
    state() {
      return this.$store.state.caseinfo;
    },

    items() {
      return this.state.payments;
    },
  },
};
</script>
