<template>
  <v-card class="mx-auto">
    <v-toolbar class="red white--text">
      <v-btn
        icon
        @click="state.dialogUpload=false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Upload File</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-col
        cols="12"
        md="6"
        class="mt-12"
      >
        <render-input
          ref="category"
          :input="fieldConfig.category"
          v-model="selected"
          :autoFetchItems="false"
        ></render-input>
      </v-col>

      <v-row>
        <v-col cols="12">
          <template v-for="(category) in categories">
            <upload-attachment-section
              class="mb-10"
              :key="category.id"
              :title="category.text"
              :getUrl="urlGetFile(state.id,category.id)"
              :uploadUrl="urlUploadFile(state.id)"
              :appendForm="Object.assign({},{categoryId: category.id},{caseId:state.id})"
              v-show="selected && selected.id === category.id"
            ></upload-attachment-section>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>


<script>
import { getDocumentTypes } from "./../../js/dropdown-service";
import * as custom from "./../../js/custom";

import UploadAttachmentSection from "./../global/upload-attachment-section";
import { InputType } from "../../js/constants";

export default {
  components: {
    "upload-attachment-section": UploadAttachmentSection,
  },
  data() {
    return {
      categories: [],
      selected: null,
    };
  },
  watch: {
    stateDialog: {
      handler: function () {
        this.selected = this.state.selectedFileType;
      },
      immediate: true,
    },
  },
  created() {
    this.getCategories();
  },
  methods: {
    urlGetFile(caseId, categoryId) {
      let url = `/case/attachment?caseId=${caseId}&categoryId=${categoryId}`;
      return url;
    },
    urlUploadFile(caseId) {
      let url = "/case/attachment/" + caseId;
      return url;
    },
    getCategories() {
      getDocumentTypes().then((res) => {
        this.categories = res.items;
        this.$refs.category.setItems(this.categories);
      });
    },
  },
  computed: {
    stateDialog() {
      return this.state.dialogUpload;
    },
    state() {
      return this.$store.state.caseinfo;
    },
    fieldConfig() {
      return {
        category: {
          name: "category",
          text: "ประเภทไฟล์",
          type: InputType.dropdown,
        },
      };
    },
  },
};
</script>