import { InputType, ContractCondition, ContractType } from '../../js/constants';
import { getFakeUrl, fakeAbbr } from '../../_helpers/fake-url';
import { aeonForm } from './aeon-utils-fields';
import * as http from './../../js/service';
import { isManagement } from '../../js/authorization';



let urlLoanType = '/dropdown/RabbitContractTypes';


export default function () {
    return {
        url: "/case/{caseId}/contract/rabbit",
        rows: [
            [
                {
                    name: "agreementNo",
                    text: "เลขที่สัญญาเงินกู้/บัตรเครดิต",
                    type: InputType.text,
                    disabled: true
                },
                {
                    name: "contractDate",
                    text: "วันที่ทำสัญญา",
                    type: InputType.datePicker,
                },
                {
                    type: InputType.offset,
                },
                {
                    type: InputType.offset,
                },

            ],
            [
                {

                    name: "creditLine",
                    text: "วงเงินกู้ยืม",
                    type: InputType.number,
                    decimal: 2,
                },
                {

                    name: "principal",
                    text: "ต้นเงิน",
                    type: InputType.number,
                    decimal: 2,
                    disabledIf: (context) => {
                        if (isManagement()) {
                            return false;
                        }

                        return true;
                    },
                },
                {
                    name: "interest",
                    text: "ดอกเบี้ย",
                    type: InputType.number,
                    decimal: 2,
                    disabledIf: (context) => {
                        if (isManagement()) {
                            return false;
                        }

                        return true;
                    }
                },
                {
                    type: InputType.offset,
                },
                {
                    type: InputType.offset,
                },
                {
                    type: InputType.offset,
                },
            ],
            // line 3
            [
                {
                    name: "total",
                    text: "ยอดหนี้รวมก่อนฟ้อง",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true
                },
                {
                    name: "totalAmountAtPlaintDate",
                    text: "ยอดหนี้รวม ณ วันฟ้อง",
                    type: InputType.number,
                    decimal: 2,
                    disabled: true
                },
                {
                    type: InputType.offset,
                },
                {
                    type: InputType.offset,
                },
                {
                    type: InputType.offset,
                },
                {
                    type: InputType.offset,
                },

            ],
            [
                {
                    type: InputType.section
                }
            ],
            [
                {
                    name: "contractType",
                    text: "ประเภทสัญญา",
                    type: InputType.dropdownRelate,
                    data: {
                        url: urlLoanType
                    },
                    disabled: true
                },
                {
                    name: "bank",
                    text: "บัญชีธนาคาร",
                    type: InputType.dropdown,
                    data: {
                        url: '/dropdown/banks'
                    },
                    rule: {
                        required: true
                    }
                },
                {
                    name: "bankAccountNumber",
                    text: "เลขที่บัญชีเงินฝาก",
                    type: InputType.text,
                    rule: {
                        required: true
                    }
                },
                {
                    name: "somePaidAmount",
                    text: "รวมยอดชำระทั้งสิ้น จำนวน",
                    type: InputType.number,
                    decimal: 2,
                },
            ],
            [
                {
                    name: "startPaymentDate",
                    text: "วันที่เริ่มต้นชำระ",
                    type: InputType.datePicker,
                },
                {
                    name: "interestRatePerYear",
                    text: "ดอกเบี้ยต่อปี",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        required: true,
                    },
                    suffixText: "%",
                },
                {
                    name: "lastPaidDate",
                    text: "ชำระล่าสุดวันที่",
                    type: InputType.datePicker,
                },
                {
                    name: "lastPaidAmount",
                    text: "จำนวนที่จ่ายครั้งล่าสุด",
                    type: InputType.number,
                    decimal: 2,
                },
            ],
            [
                {

                    name: "rb_Special_DebtRestructuringAmount",
                    text: "ยอดเงินปรับโครงสร้างหนี้",
                    type: InputType.number,
                    decimal: 2,
                    showIf: (context) => {
                        const model = context.model;

                        if (model.contractType && model.contractType.id == ContractType.RABBIT_BENEFIT_SPECIAL) {
                            return true;
                        }

                        return false;
                    }
                },
                {
                    name: "installments",
                    text: "จำนวนงวด",
                    decimal: 0,
                    type: InputType.number,
                    rule: {
                        min_value: 1,
                        max_value: 120,
                    },
                    showIf: (context) => {
                        const model = context.model;

                        if (model.contractType && [ContractType.RABBIT_CASH, ContractType.RABBIT_BENEFIT, ContractType.RABBIT_BENEFIT_SPECIAL].includes(model.contractType.id)) {
                            return true;
                        }

                        return false;
                    }
                },
                {
                    name: "paymentPerMonthIncludeVAT",
                    text: "ยอดผ่อนชำระรายเดือน",
                    type: InputType.number,
                    decimal: 2,
                    showIf: (context) => {
                        const model = context.model;

                        if (model.contractType && [ContractType.RABBIT_CASH, ContractType.RABBIT_BENEFIT, ContractType.RABBIT_BENEFIT_SPECIAL].includes(model.contractType.id)) {
                            return true;
                        }

                        return false;
                    }
                },
                {
                    name: "paymentDay",
                    text: "ชำระเงินทุกวันที่",
                    type: InputType.number,
                    decimal: 0,
                    rule: {
                        min_value: 1,
                        max_value: 31,
                    },
                    showIf: (context) => {
                        const model = context.model;

                        if (model.contractType && [ContractType.RABBIT_CASH, ContractType.RABBIT_INSTALLMENT, ContractType.RABBIT_BENEFIT_SPECIAL].includes(model.contractType.id)) {
                            return true;
                        }

                        return false;
                    }
                },
            ],

            [
                {
                    type: InputType.section,
                    text: 'สัญญาเดิม',
                    showIf: (context) => {
                        const model = context.model;

                        if (model.contractType && [ContractType.RABBIT_BENEFIT_SPECIAL].includes(model.contractType.id)) {
                            return true;
                        }

                        return false;
                    }
                }
            ],
            [
                {
                    name: "rb_Special_Old_ContractDate",
                    text: "วันที่ทำสัญญา",
                    type: InputType.datePicker,
                    showIf: (context) => {
                        const model = context.model;

                        if (model.contractType && [ContractType.RABBIT_BENEFIT_SPECIAL].includes(model.contractType.id)) {
                            return true;
                        }

                        return false;
                    }
                },
                // {
                //     name: "rb_Special_Old_PaymentDay",
                //     text: "ชำระเงินทุกวันที่",
                //     type: InputType.number,
                //     decimal: 0,
                //     rule: {
                //         min_value: 1,
                //         max_value: 31,
                //     },
                //     showIf: (context) => {
                //         const model = context.model;

                //         if (model.contractType && [ContractType.RABBIT_BENEFIT_SPECIAL].includes(model.contractType.id)) {
                //             return true;
                //         }

                //         return false;
                //     }
                // },
                {
                    name: "rb_Special_Old_StartPaymentDate",
                    text: "วันที่เริ่มต้นชำระ",
                    type: InputType.datePicker,
                    showIf: (context) => {
                        const model = context.model;

                        if (model.contractType && [ContractType.RABBIT_BENEFIT_SPECIAL].includes(model.contractType.id)) {
                            return true;
                        }

                        return false;
                    }

                },
                {
                    name: "rb_Special_Old_InterestRate",
                    text: "ดอกเบี้ยต่อปี",
                    type: InputType.number,
                    decimal: 2,
                    suffixText: "%",
                    showIf: (context) => {
                        const model = context.model;

                        if (model.contractType && [ContractType.RABBIT_BENEFIT_SPECIAL].includes(model.contractType.id)) {
                            return true;
                        }

                        return false;
                    }
                },
                {
                    type: InputType.offset
                },
            ],
            [
                {
                    name: "rb_Special_Old_PaymentPerMonthIncludeVAT",
                    text: "ยอดผ่อนชำระรายเดือน",
                    type: InputType.number,
                    decimal: 2,
                    showIf: (context) => {
                        const model = context.model;

                        if (model.contractType && [ContractType.RABBIT_BENEFIT_SPECIAL].includes(model.contractType.id)) {
                            return true;
                        }

                        return false;
                    }
                },
                {
                    name: "rb_Special_Old_Installments",
                    text: "จำนวนงวด",
                    decimal: 0,
                    type: InputType.number,
                    rule: {
                        min_value: 1,
                        max_value: 120,
                    },
                    showIf: (context) => {
                        const model = context.model;

                        if (model.contractType && [ContractType.RABBIT_BENEFIT_SPECIAL].includes(model.contractType.id)) {
                            return true;
                        }

                        return false;
                    }
                },
                {
                    type: InputType.offset
                },
                {
                    type: InputType.offset
                }
            ]

        ]
    };
}