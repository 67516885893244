<template>
  <div>
    <v-row class="text-right">
      <div class="flex-grow-1"></div>
      <v-col cols="4">
        <ind-input
          :input="fieldConfig.currentStatus"
          :value="currentStatus"
          ref="currentStatus"
          @clickedSave="submitStatus"
          :canEdit="conditionCanEditStatus"
        >
          <template v-slot:show-text="{text}">
            <v-chip
              class="ma-2"
              color="secondary"
            >
              <pre> สถานะทายาท:  <b>{{text}}</b></pre>
            </v-chip>
          </template>
        </ind-input>
      </v-col>
    </v-row>

    <master-component
      :config="config"
      :showSearch="false"
      :paramsId="null"
      :showAdd="conditionShowAdd"
    ></master-component>
  </div>
</template>



<script>
import IndInput from "./../master-render/ind-input";
import {
  getNamePrefixs,
  getRelativeStatuses,
  getDeptorRelatives,
} from "./../../js/dropdown-service";
import {
  UserRole,
  InputType,
  DeptorRelationshipType,
  StatusCase,
  DialogSize,
} from "./../../js/constants";
import { addressStructure } from "./../../js/custom";

import { fakeAbbr, getFakeUrl } from "./../../_helpers/fake-url";

export default {
  props: {
    caseId: {
      type: String,
      required: true,
    },
  },
  components: {
    "ind-input": IndInput,
  },
  data() {
    return {
      currentStatus: null,
    };
  },
  created() {
    this.getCurrentStatus();
  },

  methods: {
    getCurrentStatus() {
      let url = `/case/${this.caseId}/HeirFindoutStatus`;

      this.$http.get(url).then((res) => {
        this.currentStatus = res;
      });
    },
    submitStatus(newValue) {
      let url = `/case/${this.caseId}/HeirFindoutStatus`;

      let payload = _.cloneDeep(newValue);
      if (!payload) {
        payload = {};
      }

      this.$http.post(url, payload).then((res) => {
        this.currentStatus = newValue;
        this.$store.dispatch("caseinfo/initData");

        this.showAlertSuccess();
        this.$refs.currentStatus.close();
      });
    },
  },
  computed: {
    hasAuthorize() {
      let permission = [
        UserRole.ADMIN,
        UserRole.MANAGEMENT,
        UserRole.BACK_OFFICE,
      ];
      return (
        this.hasPermission(permission) &&
        !this.$store.getters["caseinfo/anotherConditionDisabledEdit"]
      );
    },
    conditionCanEditStatus() {
      if (!this.hasAuthorize) {
        return false;
      }

      //able to edit all status case
      //lates requirement 29/01/2020
      return true;
    },
    conditionShowAdd() {
      let statusCase = this.state.model.statusCase;
      if (statusCase && statusCase.id === StatusCase["รอสืบทายาท"].id) {
        return true;
      }
      return false;
    },
    state() {
      return this.$store.state.caseinfo;
    },
    fieldConfig() {
      return {
        currentStatus: {
          name: "currentStatus",
          text: "Current Status",
          type: InputType.dropdown,
          data: {
            promise: getRelativeStatuses(),
          },
        },
      };
    },
    config() {
      return {
        name: "relative tab",
        shortName: "ทายาท",
        search: {},
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
    urlSection() {
      let searchUrl = `/case/${this.caseId}/heir/search`;

      let getUrl = `/case/${this.caseId}/heir`;
      let addUrl = `/case/${this.caseId}/heir`;
      let editUrl = `/case/${this.caseId}/heir`;
      let removeUrl = `/case/${this.caseId}/heir`;

      // searchUrl = getFakeUrl(fakeAbbr.relative.search) + searchUrl;
      // getUrl = getFakeUrl(fakeAbbr.relative.get) + getUrl;
      // addUrl = getFakeUrl(fakeAbbr.relative.create) + addUrl;
      // editUrl = getFakeUrl(fakeAbbr.relative.update) + editUrl;
      // removeUrl = getFakeUrl(fakeAbbr.relative.remove) + removeUrl;

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl,
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
        edit: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
        remove: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "fullName",
            text: "Full Name",
          },
          {
            name: "relationshipType.text",
            text: "ความสัมพันธ์",
          },
        ],
      };
    },
    formSection() {
      return {
        size: DialogSize.fullScreen,
        conditionCanSubmit: () => {
          if (this.$store.getters["caseinfo/anotherConditionDisabledEdit"]) {
            return false;
          }
          return true;
        },
        rows: [
          [
            {
              name: "namePrefix",
              text: "คำนำหน้า",
              type: InputType.dropdown,
              data: {
                promise: getNamePrefixs(),
                url: "",
              },
              rule: {
                required: true,
              },
            },
            {
              name: "firstName",
              text: "ชื่อ",
              type: InputType.text,
              rule: {
                required: true,
              },
            },
            {
              name: "lastName",
              text: "สกุล",
              type: InputType.text,
              rule: {
                required: true,
              },
            },
          ],
          [
            {
              name: "relationshipType",
              text: "ความสัมพันธ์",
              type: InputType.dropdown,
              data: {
                promise: getDeptorRelatives(),
              },
              rule: {
                required: true,
              },
            },
            {
              name: "idCardNo",
              text: "เลขที่บัตรประชาชน",
              type: InputType.text,
              rule: {
                required: true,
                max: 13,
              },
            },
            {
              name: "age",
              text: "อายุ",
              type: InputType.number,
              decimal: 0,
              rule: {
                required: true,
                max_value: 100,
                // number: true,
              },
            },
          ],
          [
            {
              name: "marriagePlace",
              text: "สถานที่จดทะเบียนสมรส",
              type: InputType.text,
              showIf: conditionSameePariya,
            },
            {
              name: "marriageProvince",
              text: "จังหวัดที่จดทะเบียนสมรส",
              type: InputType.text,
              showIf: conditionSameePariya,
            },
            // {
            //   name: "marriageDate",
            //   text: "วันที่จดทะเบียนสมรส",
            //   type: InputType.text,
            //   showIf: conditionSameePariya,
            // },
            {
              name: "marriageIssueDate",
              text: "วันที่จดทะเบียนสมรส",
              type: InputType.datePicker,
              showIf: conditionSameePariya,
            },
          ],
          [
            {
              type: InputType.section,
              text: "ที่อยู่",
            },
          ],
          [
            {
              name: "address",
              text: "ที่อยู่",
              type: InputType.address,
              default: addressStructure(),
            },
          ],
        ],
      };
    },
  },
};

function conditionSameePariya({ model }) {
  let arr = [DeptorRelationshipType.samee, DeptorRelationshipType.pariya];
  if (model.relationshipType && arr.includes(model.relationshipType.id)) {
    return true;
  }
  return false;
}
</script>



