<template>
  <v-card>
    <ValidationObserver
      ref="observer"
      v-slot="{ dirty,valid }"
    >
      <v-toolbar color="primary">
        <v-btn
          icon
          @click="hideDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>ชื่อลูกหนี้</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-toolbar-items>
          <v-btn
            text
            @click="submit"
            :disabled="!(canEdit && valid && checkedActive)"
          >Submit</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text :disabled="!canEdit">
        <v-alert
          type="error"
          v-if="items.length === 0"
        >Please update name.</v-alert>
        <v-alert
          type="error"
          v-else-if="!checkedActive"
        >Please selected active name.</v-alert>

        <v-row>
          <show-model :text="payload"></show-model>

          <v-col cols="12">
            <v-simple-table
              id="name-table"
              dense
            >
              <thead>
                <tr>
                  <th class="text-center">No.</th>
                  <th class="text-center">คำนำหน้า</th>
                  <th class="text-center">ชื่อ</th>
                  <th class="text-center">สกุล</th>
                  <th class="text-center">Active</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in items"
                  :key="index"
                >
                  <td class="text-center">{{index+1}}</td>
                  <td>
                    <render-input
                      :input="fieldConfig.namePrefix"
                      v-model="item.namePrefix"
                      :model="item"
                    ></render-input>
                  </td>
                  <td>
                    <render-input
                      :input="fieldConfig.firstName"
                      v-model="item.firstName"
                    ></render-input>
                  </td>
                  <td>
                    <render-input
                      :input="fieldConfig.lastName"
                      v-model="item.lastName"
                    ></render-input>
                  </td>
                  <td class="text-center pt-0">
                    <v-icon
                      v-if="item.isActive"
                      color="success"
                      @click="changePrimary(index,false)"
                    >check_box</v-icon>
                    <v-icon
                      v-else
                      @click="changePrimary(index,true)"
                    >check_box_outline_blank</v-icon>
                  </td>
                  <td class="text-center pt-0">
                    <v-icon
                      v-if="canEdit"
                      color="error"
                      @click="remove(item,index)"
                    >delete</v-icon>
                  </td>
                </tr>
                <tr v-show="items.length === 0">
                  <td
                    colspan="100%"
                    class="text-center"
                  >No Result</td>
                </tr>
                <tr>
                  <td
                    colspan="100%"
                    class="text-right"
                  >
                    <v-btn
                      v-if="canEdit"
                      color="primary mr-12"
                      @click="addRecord()"
                    >Add</v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </ValidationObserver>
  </v-card>
</template>


<script>
import { isEmpty } from "./../../js/custom";
import { InputType } from "./../../js/constants";
import { getNamePrefixs } from "./../../js/dropdown-service";
import { fakeAbbr, getFakeUrl } from "./../../_helpers/fake-url";

export default {
  props: {
    caseId: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    hideDialog() {
      this.state.dialogName = false;
    },
    getData() {
      let url = `/case/${this.caseId}/deptorName`;

      this.$http.get(url).then((res) => {
        if (res) {
          this.items = res.items;
        }
      });
    },
    addRecord() {
      let record = {
        namePrefix: null,
        namePrefixRemark: null,
        firstName: "",
        lastName: "",
        isActive: false,
      };
      this.items.push(record);
    },
    remove(item, index) {
      let result = false;
      if (!isEmpty(item)) {
        if (confirm("คุณแน่ใจหรือไม่ที่จะลบรายการนี้ ?")) {
          result = true;
        }
      } else {
        result = true;
      }

      if (result) {
        this.items.splice(index, 1);
      }
    },
    submit() {
      let url = `/case/${this.caseId}/deptorName`;

      this.$http.put(url, this.payload).then((res) => {
        this.items = res.items;
        this.$emit("changed-deptor-name", this.currentName);
        this.hideDialog();
        this.showAlertSuccess();
      });
    },
    changePrimary(index, newValue) {
      this.checkedActive = false;
      this.items.forEach((item, i) => {
        item.isActive = false;
        if (newValue && index === i) {
          item.isActive = true;
          this.checkedActive = true;
        }
      });
    },
  },
  computed: {
    fieldConfig() {
      return {
        namePrefix: {
          name: "namePrefix",
          text: "คำนำหน้า",
          type: InputType.dropdown,
          data: {
            promise: getNamePrefixs(),
            url: "",
          },
          rule: {
            required: true,
          },
        },
        firstName: {
          name: "firstName",
          text: "ชื่อ",
          type: InputType.text,
          rule: {
            required: true,
          },
        },
        lastName: {
          name: "lastName",
          text: "สกุล",
          type: InputType.text,
          rule: {
            required: true,
          },
        },
      };
    },
    checkedActive: {
      get() {
        if (this.currentName) {
          return true;
        } else {
          return false;
        }
      },
      set(val) {},
    },
    payload() {
      return {
        caseId: this.caseId,
        items: this.items,
      };
    },
    currentName() {
      const activeName = this.items.find((x) => x.isActive == true);
      return activeName;
    },
    stateDialog() {
      return this.state.dialogName;
    },
    state() {
      return this.$store.state.caseinfo;
    },
  },
};
</script>

<style scoped>
#name-table tr td {
  padding-top: 10px;
}
</style>


