<template>
  <div>
    <v-divider></v-divider>
    <v-tabs>

      <v-tab>
        <v-icon left>gavel</v-icon>คดีความ
        <v-badge
          v-if="conditionShowBadgeUpdateSue"
          color="deep-purple accent-4"
        >
          <template v-slot:badge>
            <v-icon class="white--text">mdi-bell</v-icon>
          </template>
        </v-badge>
      </v-tab>

      <v-tab style="padding-right:20px">
        <v-icon left>money</v-icon>เบิกเงิน
        <v-badge
          v-if="conditionShowBadgePayment"
          color="deep-purple accent-4"
        >
          <template v-slot:badge>
            <v-icon class="white--text">mdi-bell</v-icon>
          </template>
        </v-badge>
      </v-tab>

      <v-tab style="padding-right:20px">
        <v-icon left>mdi-file-document
        </v-icon>สัญญายอม
      </v-tab>

      <v-tab>
        NCB
      </v-tab>

      <v-tab-item>
        <v-container>
          <update-status-sue></update-status-sue>
        </v-container>
      </v-tab-item>

      <v-tab-item>
        <v-container>
          <lawyer-payment-tab></lawyer-payment-tab>
        </v-container>
      </v-tab-item>

      <v-tab-item>
        <v-container>
          <sanyayom></sanyayom>
        </v-container>
      </v-tab-item>

      <v-tab-item>
        <v-container>
          <NCB></NCB>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import UpdateStatusSue from "./update-status-sue";
import LawyerPaymentTab from "./lawyer-payment-tab";
import Sanyayom from "./sanyayom";
import NCB from "./ncb";
import { StatusPayment, UserRole } from "./../../js/constants";

import { getFakeUrl, fakeAbbr } from "./../../_helpers/fake-url";
import { mapGetters } from "vuex";

export default {
  components: {
    UpdateStatusSue,
    LawyerPaymentTab,
    Sanyayom,
    NCB,
  },
  computed: {
    ...mapGetters({
      conditionStatusCaseNotificationToLawyer:
        "caseinfo/conditionStatusCaseNotificationToLawyer",
      countPaymentBadgeToShowBackOffice:
        "caseinfo/countPaymentBadgeToShowBackOffice",
      countReadyToRequestPayment: "caseinfo/countReadyToRequestPayment",
    }),
    conditionShowBadgePayment() {
      return (
        this.countPaymentBadgeToShowBackOffice +
          this.countReadyToRequestPayment >
        0
      );
    },
    conditionShowBadgeUpdateSue() {
      return this.conditionStatusCaseNotificationToLawyer;
    },
    state() {
      return this.$store.state.caseinfo;
    },
  },
};
</script>
