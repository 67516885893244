<template>
  <div
    v-if="config.showPanel"
    class="pb-4"
  >
    <show-model :text="config"></show-model>
    <v-card>
      <div class="text-right">
        <v-menu>
          <template
            v-slot:activator="{ on }"
            v-if="showSettingButton"
          >
            <v-icon
              small
              left
              v-on="on"
            >build</v-icon>
          </template>

          <v-list dense>
            <v-list-item
              v-for="(item,index) in settingItems"
              :key="index"
              @click="item.func()"
            >
              <v-icon left>{{item.icon}}</v-icon>
              {{item.text}}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-card-text class="pa-0">
        <div class="">
          <h2 class="d-inline-block">{{ config.text }}</h2>
          <v-chip
            class="ml-2"
            style="margin-top:-8px"
            :class="conditionColor"
          >{{ statusText }}</v-chip>
          <span class="ml-1 caption">
            {{
            config && config.latestDate ? `${config.latestDate}` : ""
            }}
          </span>

          <!-- <v-btn
            text
            color="primary"
            class="mt-n2 d-block"
            v-if="conditionShowApprove"
            @click="approve"
          >อนุมัติ</v-btn> -->

          <ValidationObserver
            ref="observer"
            v-slot="{ valid }"
          >

            <v-row
              class="pt-3"
              v-if="conditionShowApprove"
            >
              <v-col cols="3">
                <render-input
                  :input="fieldConfig.consider"
                  v-model="config.approveResult"
                ></render-input>
              </v-col>
              <v-col cols="1">
                <v-btn
                  color="primary"
                  @click="consider()"
                  :disabled="!valid"
                >สรุปผล</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="conditionShowReasonReject">
              <v-col cols="4">
                <render-input
                  :class="{'mt-n2' : conditionShowApprove, 'mt-3' : !conditionShowApprove}"
                  :input="fieldConfig.reasonReject"
                  v-model="config.reasonReject"
                  style="width: 455px;"
                  :disabled="!conditionShowApprove"
                ></render-input>
              </v-col>
            </v-row>
          </ValidationObserver>
          <v-divider v-if="conditionShowReasonReject"></v-divider>
        </div>

        <v-row>
          <v-col cols="6">
            <pre>{{ config.describe }}
            </pre>
            <pre><b>{{ config.checkListFiles.length }} ไฟล์ ได้แก่</b>
            </pre>

            <!-- <template v-for="(checklist, index) in config.checkListFiles">
              <render-input
                v-if="conditionShowCheckList"
                :key="index"
                :input="
                  Object.assign({}, fieldConfig.checklist, {
                    text: checklist.text,
                  })
                "
                v-model="checklist.checked"
                @change="toggleFile(checklist)"
                class="checklist-file"
                :disabled="!checklist.allowEdit"
              ></render-input>
              <p
                v-else
                :key="index"
              >{{index+1}}. {{ checklist.text }}</p>
            </template> -->

            <span>

              <ValidationObserver
                ref="observer"
                v-slot="{ valid }"
              >
                <div v-for="(checklist, index) in config.checkListFiles">
                  <render-input
                    :key="index"
                    :input="
                  Object.assign({}, fieldConfig.checklist, {
                    text: checklist.text,
                  })
                "
                    v-model="config.checkListFiles[index].checked"
                    class="checklist-file"
                    :disabled="!config.checkListFiles[index].allowEdit"
                    v-if="conditionShowCheckList && checklist.allowEdit"
                  ></render-input>

                  <p v-else>{{index+1}}. {{ checklist.text }}</p>

                  <v-row
                    class="ml-3 mt-2 mb-n8"
                    v-if="[PaymentPart.fong].includes(config.paymentPartId) && !checklist.text.includes('คำฟ้อง')"
                  >
                    <v-col>
                      <render-input
                        v-model="config.checkListFiles[index].billRunningNo"
                        :input="(conditionShowCheckList && checklist.allowEdit)?fieldConfig.billRunningNo: Object.assign({}, fieldConfig.billRunningNo, {
                               disabled: true,
                      })"
                      ></render-input>

                    </v-col>
                    <v-col>
                      <render-input
                        v-model="config.checkListFiles[index].billBookNo"
                        :input="(conditionShowCheckList && checklist.allowEdit)?fieldConfig.billBookNo: Object.assign({}, fieldConfig.billBookNo, {
                               disabled: true,
                      })"
                      ></render-input>
                    </v-col>
                    <v-col class="px-2">
                      <render-input
                        v-model="config.checkListFiles[index].billDate"
                        :input="(conditionShowCheckList && checklist.allowEdit)?fieldConfig.billDate: Object.assign({}, fieldConfig.billDate, {
                               disabled: true,
                      })"
                      ></render-input>
                    </v-col>
                  </v-row>
                  <v-row
                    class="ml-8 mt-2 mb-n8"
                    v-if="config.paymentPartId == PaymentPart.namKamBangKub &&  checklist.text.includes('ใบเสร็จ')"
                  >
                    <v-col cols="4">
                      <render-input
                        v-model="config.checkListFiles[index].pipaksaReceiptAmount"
                        :input="(conditionShowCheckList && checklist.allowEdit)?fieldConfig.pipaksaReceiptAmount: Object.assign({}, fieldConfig.pipaksaReceiptAmount, {
                               disabled: true,
                      })"
                      ></render-input>

                    </v-col>
                  </v-row>

                </div>
                <v-btn
                  v-if="conditionShowCheckList && config.checkListFiles.some(x=>x.allowEdit)"
                  color="primary"
                  class="mt-8"
                  :disabled="!valid"
                  @click="submitChecklistFile()"
                >submit</v-btn>
              </ValidationObserver>
            </span>
            <v-row class="mt-5">
              <v-col>
                <upload-attachment-section
                  class="mb-2"
                  :getUrl="urlGetAttachment"
                  :uploadUrl="urlUploadAttachment"
                  :appendForm="Object.assign({}, { caseId: state.id })"
                  :showUploadButton="config.showAttachFileButton"
                  :showRemove="config.showDeleteFileButton"
                ></upload-attachment-section>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            style="border-left: 1px solid #ccd0d5;padding-left: 10px;"
          >

            <ValidationObserver
              ref="observer"
              v-slot="{ valid }"
            >

              <v-alert
                text
                color="info"
                v-if="config.headLawyerPaymentInfo"
              >
                <div v-html="config.headLawyerPaymentInfo ">

                </div>
              </v-alert>
              <v-alert
                text
                color="info"
                v-if="!config.headLawyerPaymentInfo && $store.getters['pageinfo/isSupportMode']"
              >

                <v-btn
                  color="warning"
                  class="ml-5"
                  @click="addLawyerManagementPayment()"
                  small
                >
                  <v-icon>mdi-plus</v-icon>ซ่อมค่าบริหารพื้นที่
                </v-btn>
                <div v-html="config.headLawyerPaymentInfo ">

                </div>
              </v-alert>

              <h3>สรุปยอดเบิกเงิน</h3>

              <section>
                <h4 class="success--text">เงินได้</h4>
                <v-row
                  v-for="(item, index) in listTypePlus"
                  :key="index"
                >
                  <v-col cols="9">{{ item.name }}</v-col>
                  <v-col :cols="secondRows">
                    <render-input
                      :input="fieldConfig.amount"
                      :disabled="!item.allowEditAmount"
                      v-model="item.amount"
                    ></render-input>
                  </v-col>
                </v-row>
              </section>

              <v-divider class="my-2 mb-4"></v-divider>
              <v-row>
                <v-col cols="2">
                  <render-input
                    :input="fieldConfig.checklist"
                    v-model="otherChecked"
                    :disabled="!(other.allowEditAmount)"
                    class="d-inline-block"
                  ></render-input>อื่นๆ
                </v-col>
                <v-col cols="7">
                  <render-input
                    :input="fieldConfig.otherName"
                    v-model="other.name"
                    :disabled="!(otherChecked && other.allowEditAmount)"
                    :required="otherChecked"
                  ></render-input>
                </v-col>
                <v-col cols="3">
                  <render-input
                    :input="fieldConfig.otherAmount"
                    v-model="other.amount"
                    :disabled="!(otherChecked && other.allowEditAmount)"
                    :required="otherChecked"
                  ></render-input>
                </v-col>
              </v-row>

              <v-divider class="my-2"></v-divider>

              <v-row class="info--text">
                <v-col cols="9">
                  <h4>สรุปยอดเงิน</h4>
                </v-col>
                <v-col>
                  <h4>{{ summaryAmount | currency(2) }}</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  offset-md="4"
                  class="mt-7"
                >
                </v-col>
              </v-row>

              <div class="text-right mt-7">
                <div v-if="loading">
                  <span>Loading...</span>
                </div>
                <div v-else>
                  <v-btn
                    class="error white--text"
                    v-if="config.showApproveButton"
                    @click="cancelRequestPayment"
                  >{{ config.textCancelRequest }}</v-btn>

                  <v-btn
                    class="primary white--text"
                    v-if="config.showRequestButton"
                    @click="requestPayment"
                    :disabled="!valid"
                  >{{ config.textRequest }}</v-btn>

                </div>
              </div>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
  </div>
</template>

<script>
import {
  StatusPayment,
  StatusCase,
  StatusSue,
  UserRole,
  InputType,
  RevertType,
  PaymentType,
  PaymentPart,
} from "./../../js/constants";

import { getUID, sumList } from "./../../js/custom";
import { getFakeUrl, fakeAbbr } from "./../../_helpers/fake-url";
import UploadAttachmentSection from "./../global/upload-attachment-section";

export default {
  props: {
    config: Object,
  },
  data() {
    return {
      checkListFiles: [],
      other: {
        allowEditAmount: false,
      },
      approveModel: {
        result: null,
        reasonReject: null,
      },
      otherChecked: false,
      PaymentPart,
      InputType,
      loading: false,
    };
  },
  components: {
    UploadAttachmentSection,
  },
  watch: {
    config: {
      handler(newVal) {
        if (!this.config.showPanel) {
          return;
        }

        let obj = this.config.list.find((x) => x.type == "other");

        if (obj && obj.amount > 0) {
          this.otherChecked = true;
        } else {
          this.otherChecked = false;
        }
        this.other = _.cloneDeep(obj);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    requestPayment() {
      let url = `/case/${this.state.id}/payment/part/${this.config.paymentPartId}/request`;

      let payload = _.cloneDeep(this.config);
      let other_index = payload.list.findIndex((x) => x.type === "other");

      if (other_index >= 0) {
        payload.list.splice(other_index, 1, this.other);
      } else {
        payload.list.push(this.other);
      }

      this.loading = true;

      this.$http
        .post(url, payload)
        .then((res) => {
          this.showAlertSuccess("กำลังยื่นขอเบิกเงิน");
          // this.$store.commit("caseinfo/updatePayment", res);
          this.$store.dispatch("caseinfo/getCurrentPaymentStatus");
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 300);
        });
    },
    cancelRequestPayment() {
      let url = `/case/${this.state.id}/payment/part/${this.config.paymentPartId}/cancelRequest`;

      this.loading = true;

      this.$http
        .post(url)
        .then((res) => {
          this.showAlertSuccess("ยกเลิกการเบิกเงินเรียบร้อยแล้ว");
          // this.$store.commit("caseinfo/updatePayment", res);
          this.$store.dispatch("caseinfo/getCurrentPaymentStatus");
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 300);
        });
    },
    consider() {
      if (this.config.approveResult?.id == true) {
        this.approve();
      } else if (this.config.approveResult?.id == false) {
        this.reject();
      }
    },
    approve() {
      let hasNotUpload = this.config.checkListFiles.some((x) => !x.checked);
      if (
        hasNotUpload &&
        !confirm(
          "ไฟล์ที่ระบบต้องการยังไม่ครบ\nคุณแน่ใจหรือไม่ที่จะอนุมัติรายการเบิกนี้?"
        )
      ) {
        return;
      }

      let url = `/case/${this.state.id}/payment/part/${this.config.paymentPartId}/approve`;

      this.$http.post(url).then((res) => {
        this.showAlertSuccess();
        this.$store.dispatch("caseinfo/getCurrentPaymentStatus");
        // this.$store.commit("caseinfo/updatePayment", res);
      });
    },
    reject() {
      let url = `/case/${this.state.id}/payment/part/${this.config.paymentPartId}/reject`;
      let payload = {
        id: this.config.id,
        reasonReject: this.config.reasonReject,
      };
      this.$http.post(url, payload).then((res) => {
        this.showAlertSuccess();
        this.$store.dispatch("caseinfo/getCurrentPaymentStatus");
      });
    },
    submitChecklistFile() {
      let url = `/case/${this.state.id}/payment/part/${this.config.paymentPartId}/UpdateChecklist`;
      let payload = _.cloneDeep(this.config);

      this.$http.post(url, payload).then((res) => {
        this.showAlertSuccess();
        this.$store.dispatch("caseinfo/getNCBData");
      });
    },
    addLawyerManagementPayment() {
      let url = `/support/AddLawyerManagementPayment?vichacheepPaymentId=${this.config.id}`;

      if (confirm("คุณแน่ใจหรือไม่ที่เพิ่มค่าบริหารพื้นที่")) {
        this.$http.post(url).then((res) => {
          this.showAlertSuccess();
        });
      }
    },
  },
  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
    urlGetAttachment() {
      let url = `/case/${this.state.id}/payment/attachment/${this.config.paymentPartId}`;
      return url;
    },
    urlUploadAttachment() {
      let url = `/case/${this.state.id}/payment/attachment/${this.config.paymentPartId}`;
      return url;
    },
    showSettingButton() {
      return this.settingItems.some((x) => x.condition());
    },
    checklistRows() {
      return [
        [
          {
            text: "เลขที่ใบเสร็จทางราชการ",
            name: "billRunningNo",
            type: InputType.text,
          },
          {
            text: "เล่มที่ใบเสร็จทางราชการ",
            name: "billBookNo",
            type: InputType.text,
          },
          {
            text: "วันที่ในใบเสร็จ",
            name: "billDate",
            type: InputType.datePicker,
            columnClass: "col-md-5",
          },
        ],
      ];
    },
    settingItems() {
      return [
        // {
        //   text: "ยกเลิกการอนุมัติ",
        //   icon: "block",
        //   condition: () => {
        //     return this.config.showCancelApprove;
        //   },
        //   func: () => {
        //     if (confirm("คุณต้องการยกเลิกการอนุมัติรายการนี้ใช่หรือไม่")) {
        //       let url = `/case/revert`;
        //       let payload = {
        //         data: [this.config.paymentPartId],
        //         caseId: this.state.id,
        //         type: RevertType["ยกเลิกอนุมัติ"].id,
        //       };
        //       this.$http.post(url, payload).then((res) => {
        //         this.$store.commit("caseinfo/updatePayment", res);
        //       });
        //     }
        //   },
        // },
      ];
    },
    listTypePlus() {
      return this.config.list.filter((x) => x.type === "+");
    },
    // listTypeAdvance() {
    //   return this.config.list.filter((x) => x.type === "-");
    // },
    summaryAmount() {
      let cloned = _.cloneDeep(this.config.list.filter((x) => x.type === "+"));
      if (this.otherChecked) {
        cloned.push(_.cloneDeep(this.other));
      }

      let result = sumList(cloned, "amount");
      return result;
    },

    status() {
      if (this.config) {
        let { status } = this.config;
        return status;
      }
      return null;
    },
    conditionColor() {
      if (!this.status) {
        return "cyan white--text";
      }

      switch (this.status.id) {
        case StatusPayment["รออนุมัติ"].id:
          return "orange white--text";
        case StatusPayment["อนุมัติแล้ว"].id:
          return "teal white--text";
        case StatusPayment["จ่ายเงินแล้ว"].id:
          return "success white--text";

        default:
          return "Secondary";
      }
    },
    statusText() {
      if (this.status) {
        return this.status.text;
      }
      return null;
    },
    conditionShowApprove() {
      return this.config.showApproveButton;
    },
    conditionShowReasonReject() {
      return this.conditionShowApprove || this.config.reasonReject;
    },
    conditionShowCheckList() {
      let permission = [
        UserRole.ADMIN,
        UserRole.MANAGEMENT,
        UserRole.BACK_OFFICE,
      ];
      return this.hasPermission(permission);
    },
    conditionShowAttachment() {
      let permission = [
        UserRole.ADMIN,
        UserRole.MANAGEMENT,
        UserRole.BACK_OFFICE,
      ];
      return this.hasPermission(permission);
    },
    fieldConfig() {
      return {
        checklist: {
          name: "checklist",
          text: "",
          type: InputType.checkBox,
        },
        billRunningNo: {
          text: "เลขที่ใบเสร็จทางราชการ",
          name: "billRunningNo",
          type: InputType.text,
        },
        billBookNo: {
          text: "เล่มที่ใบเสร็จทางราชการ",
          name: "billBookNo",
          type: InputType.text,
        },
        billDate: {
          text: "วันที่ในใบเสร็จ",
          name: "billDate",
          type: InputType.datePicker,
        },
        pipaksaReceiptAmount: {
          text: "จำนวนเงิน",
          name: "pipaksaReceiptAmount",
          type: InputType.number,
          decimal: 2,
        },
        name: {
          name: "name",
          text: "รายละเอียด",
          type: InputType.text,
        },
        amount: {
          name: "amount",
          text: "จำนวนเงิน",
          type: InputType.number,
          decimal: 2,
          rule: {
            min_value: 0,
          },
        },
        otherName: {
          name: "name",
          text: "รายละเอียด",
          type: InputType.text,
          rule: {
            required: this.otherChecked,
          },
        },
        otherAmount: {
          name: "amount",
          text: "จำนวนเงิน",
          type: InputType.number,
          decimal: 2,
          rule: {
            min_value: this.otherChecked ? 1 : 0,
          },
        },
        consider: {
          name: "consider",
          text: "ผลการพิจารณา",
          type: InputType.dropdown,
          rule: {
            required: true,
          },
          data: {
            promise: Promise.resolve({
              items: [
                { id: true, text: "อนุมัติ" },
                { id: false, text: "ปฏิเสธ" },
              ],
            }),
          },
          columnClass: "col-md-6",
        },
        reasonReject: {
          name: "reasonReject",
          text: "เหตุผลที่ปฏิเสธ",
          type: InputType.textArea,
          columnClass: "col-md-6",
          rule: {
            required: this.config.approveResult?.id == false,
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.checklist-file >>> .v-messages {
  display: none;
}
</style>
