<template>
  <div>
    <uploadFile-button
      @changedFile="handleFile"
      icon="cloud_upload"
      text="Upload Transaction"
      accept="excel"
      :disabled="loading"
      :multiple="false"
      class="d-inline-block"
      v-if="state.model.creditor && state.model.creditor.id === AIRA_ID"
    ></uploadFile-button>

    <!-- <button-download text="ฟอร์มคำนวณทุนทรัพย์" :url="calSheetUrlDownload"></button-download> -->
  </div>
</template>


<script>
import { fakeAbbr, getFakeUrl } from "./../../_helpers/fake-url";

import uploadFileButton from "./../../components/custom/uploadfile-button";
import { CategoryFile, Customer } from "./../../js/constants";

export default {
  components: {
    "uploadFile-button": uploadFileButton
  },
  data() {
    return {
      loading: false,
      AIRA_ID: Customer.AIRA_ID
    };
  },
  methods: {
    handleFile(files) {
      if (!(files && files.length > 0)) {
        return;
      }
      let url = this.calSheetUrlUpload;

      var data = new FormData();
      data.append("file", files[0]);
      data.append("caseId", this.state.id);
      data.append("categoryId", CategoryFile.CAL_SHEET);

      this.loading = true;
      this.$http
        .uploadFile(url, data)
        .then(res => {
          this.showAlertSuccess("File has been uploaded successfully");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
    calSheetUrlDownload() {
      return `/case/${this.state.id}/calsheet/download`;
    },
    calSheetUrlUpload() {
      return `/case/${this.state.id}/calsheet/upload`;
    }
  }
};
</script>