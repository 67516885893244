<template>
  <div>
    <v-alert
      outlined
      :type="item.type"
      text
      prominent
      class="py-5"
      :icon="item.icon"
    >
      <v-row align="center">
        <!-- <v-col class="grow">{{item.title}}</v-col> -->
        <v-col class="grow">{{title}}</v-col>
        <v-col v-if="item.template == '1'">
          <v-btn
            class="theme--light mt-3"
            outlined
            plain
            @click="callService()"
          >{{item.buttonText}}</v-btn>
        </v-col>
      </v-row>

      <div v-if="item.template == '2'">
        <ValidationObserver
          ref="observer"
          v-slot="{ valid }"
        >
          <master-render
            class="mt-4"
            :rows="item.rows"
            v-model="model"
          ></master-render>

          <div>
            <v-btn
              outlined
              plain
              class="mt-3"
              :class="{'theme--light':valid}"
              @click="callService()"
              :disabled="!valid"
            >{{item.buttonText}}</v-btn>
          </div>
        </ValidationObserver>
      </div>

      <div v-if="item.template == '3'">
        <ValidationObserver
          ref="observer"
          v-slot="{ valid }"
        >
          <v-row class="mt-4">
            <v-col cols="5">
              <render-input
                ref="dropdown3"
                :input="configDropdown3"
                v-model="model[item.optionName]"
              ></render-input>
            </v-col>
          </v-row>

          <div>
            <v-btn
              outlined
              plain
              class="mt-3"
              :class="{'theme--light':valid}"
              @click="callService()"
              :disabled="!valid"
            >{{item.buttonText}}</v-btn>
          </div>
        </ValidationObserver>
      </div>
    </v-alert>
  </div>
</template>

<script>
import {
  StatusCase,
  StatusSue,
  UserRole,
  InputType,
} from "./../../js/constants";
import * as custom from "./../../js/custom";

export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      model: {},
    };
  },
  watch: {
    options3: {
      handler(newVal) {
        this.$nextTick(() => {
          if (this.$refs.dropdown3) {
            this.$refs.dropdown3.setItems(newVal);
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    callService() {
      if (confirm("คุณแน่ใจหรือไม่ที่เปลี่ยนแปลงข้อมูลดังกล่าว ?")) {
        this.item.func(this.model).then((res) => {
          this.state.model.statusCase = res.statusCase;
          this.state.model.statusSue = res.statusSue;
          this.showAlertSuccess();

          this.$store.dispatch("caseinfo/initData");
        });
      }
    },
    initModel() {
      this.model = {};

      if (this.item.rows) {
        custom.createDynamicModel(this, this.model, this.item.rows);
      }
    },
  },
  computed: {
    configDropdown3() {
      return {
        name: this.item.optionName,
        text: this.item.optionLabel,
        type: InputType.dropdown,
        columnClass: "col-sm-12 col-md-5",
        rule: {
          required: true,
        },
      };
    },
    options3() {
      let items = [];
      if (this.item.template === "3") {
        var filtered = this.item.options.filter((option) => option.condition());
        items = filtered.map((x) => x.value);
      }
      return items;
    },
    title() {
      let result = "";
      if (this.item.titleFunc) {
        result = this.item.titleFunc();
      } else {
        result = this.item.title;
      }
      return result;
    },
    state() {
      return this.$store.state.caseinfo;
    },
  },
};
</script>