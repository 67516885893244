<template>
  <div>
    <show-model :text="renders"></show-model>

    <v-card
      v-for="(render,index)  in renders"
      :key="index"
      class="mb-10"
    >
      <v-card-title>{{render[0].groupName}}</v-card-title>
      <v-card-text>
        <v-row
          v-for="(item) in render"
          :key="item.id"
          class
        >
          <v-col cols="9">{{item.text}}</v-col>
          <v-col cols="3">
            <v-btn
              text
              class="blue--text"
              @click="openDialog(item)"
            >Manage</v-btn>
            <!-- <a href="#" @click="openDialog(item)">Manage</a> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- modal -->
    <v-row>
      <v-col>
        <v-dialog
          v-model="state.dialogUpload"
          max-width="800px"
          @keydown.esc="state.dialogUpload=false"
        >
          <upload-attachment-dialog></upload-attachment-dialog>
        </v-dialog>
      </v-col>
    </v-row>
    <!-- end modal -->
  </div>
</template>


<script>
import { getDocumentTypes } from "./../../js/dropdown-service";
import UploadAttachmentDialog from "./upload-attachment-dialog";

export default {
  components: {
    "upload-attachment-dialog": UploadAttachmentDialog,
  },
  data() {
    return {
      renders: [],
    };
  },
  created() {
    this.getMaster();
  },
  methods: {
    openDialog(item) {
      this.state.selectedFileType = item;
      this.state.dialogUpload = true;
    },
    getMaster() {
      getDocumentTypes().then((res) => {
        this.renders = _.groupBy(res.items, "group");
      });
    },
  },
  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
  },
};
</script>