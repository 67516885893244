<template>
  <div
    style="min-height:100px"
    class="mb-10"
  >
    <show-model :text="payload"></show-model>

    <div>
      <v-alert
        type="info"
        outlined
        class="my-10 elevation-7"
        v-if="items.length === 0"
      >ยังไม่มีการอัพเดทข้อมูลคดีความของเคสนี้</v-alert>
    </div>

    <v-card
      class="mt-5 elevation-5 grey lighten-4"
      v-if="showCondition"
    >
      <ValidationObserver
        ref="observer"
        v-slot="{ valid }"
      >
        <v-card-title class="">
          <h2 class=""> อัพเดทคดี</h2>

          <div class="flex-grow-1"></div>
          <div v-if="loading">
            <span>Loading...</span>
          </div>
          <v-btn
            class="white--text"
            color="primary"
            @click="postStatusSue"
            :disabled="!valid"
          >Update</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <render-input
                ref="option"
                :input="fieldConfig.option"
                v-model="selectedOption"
                :autoFetchItems="false"
              ></render-input>
            </v-col>
          </v-row>

          <master-render
            :rows="postRows"
            v-model="post"
          ></master-render>

        </v-card-text>
      </ValidationObserver>
    </v-card>

    <div class="mt-4"></div>

    <v-row v-if="items.length > 0">
      <v-col class="text-right">
        <v-btn
          @click="expandAll"
          class="black white--text"
          :disabled="disabledExpandButton"
        >
          <v-icon>mdi-expand-all</v-icon>&nbsp;Expand all
        </v-btn>
        <v-btn
          @click="collapseAll"
          class="ml-3 black white--text"
          :disabled="disabledCollapseButton"
        >
          <v-icon>mdi-collapse-all</v-icon>&nbsp; Collapse all
        </v-btn>
      </v-col>
    </v-row>

    <show-model :text="items"></show-model>

    <v-expansion-panels
      v-model="panel"
      multiple
      class="mt-5"
    >
      <update-status-sue-panel
        v-for="(item, index) in itemsOrdered"
        :key="item.id"
        class="elevation-3"
        :class="{
          'v-sheet theme--light v-alert--border v-alert--dense v-alert--text v-alert--border-left teal--text':
            index === 0,
        }"
        v-model="itemsOrdered[index]"
        :rows="
          generateRows({
            statusCase: item.statusCaseBeforePost,
            statusSue: item.statusSueBeforePost,
            option: item.option,
          })
        "
        @clickPanelHeader="clickPanelHeader"
        @submitted="editedSuccess($event)"
        :canEdit="hasAuthorize"
        :canDelete="item.canDelete"
      ></update-status-sue-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import UpdateStatusSuePanel from "./update-status-sue-panel";
import * as custom from "./../../js/custom";
import manageStorage from "./../../js/manage-localstorage";
import {
  InputType,
  DateFormat,
  StatusSue,
  StatusCase,
  CollapseMode,
  UserRole,
} from "./../../js/constants";
import { getUI } from "./update-status/config-sue-view";

import { getFakeUrl, fakeAbbr } from "../../_helpers/fake-url";
import { mapActions } from "vuex";

const storageName = "update-status";

export default {
  components: {
    "update-status-sue-panel": UpdateStatusSuePanel,
  },
  data() {
    return {
      options: [],
      selectedOption: null,
      post: {},
      postRows: [],
      panel: [],
      currentMode: null,
      collapseMode: null,
    };
  },
  watch: {
    selectedOption: {
      handler(newVal) {
        this.postRows = this.generateRows({
          statusCase: this.statusCase,
          statusSue: this.statusSue,
          option: newVal,
        });
        this.initModel();
      },
    },
    statusCase: {
      handler(newVal) {
        this.getOption();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.collapseMode = CollapseMode.collapse;
  },
  methods: {
    ...mapActions("loading", ["showLoading", "hideLoading"]),
    clickPanelHeader() {
      this.editCollapseInStorage(null);
    },
    expandAll() {
      this.panel = [];
      for (let i = 0; i < this.items.length; i++) {
        this.panel.push(i);
      }
      this.editCollapseInStorage(CollapseMode.expand);
    },
    collapseAll() {
      this.panel = [];
      this.editCollapseInStorage(CollapseMode.collapse);
    },
    decisionCollapse() {
      if (!this.collapseMode) {
        return;
      } else if (this.collapseMode === CollapseMode.expand) {
        this.expandAll();
      } else if (this.collapseMode === CollapseMode.collapse) {
        this.collapseAll();
      }
    },
    getOption() {
      if (!this.showCondition) {
        return;
      }

      let url = `/case/getSueOption`;

      let payload = {
        statusCase: this.statusCase,
        statusSue: this.statusSue,
      };

      this.$http.post(url, payload).then((res) => {
        this.options = res.items;
        if (this.options.length === 1) {
          this.selectedOption = this.options[0];
        }

        this.$nextTick(() => {
          this.$refs.option.setItems(this.options);
        });
      });
    },
    generateRows({ statusCase, statusSue, option }) {
      if (!option) {
        return [];
      }

      var plaintDate = this.state.model.plaintDate;

      let rows = getUI({ plaintDate, statusCase, statusSue, option });
      return rows;
    },

    initModel() {
      this.post = {};
      custom.createDynamicModel(this, this.post, this.postRows);
    },
    editedSuccess(item, addMode) {
      //fetch payment data
      this.$store.dispatch("caseinfo/initData");

      this.selectedOption = null;

      // this.$nextTick(() => {
      //   //expand latest item added
      //   if (addMode) {
      //     setTimeout(() => {
      //       this.editCollapseInStorage(null);
      //       let latesIndex = this.items.length - 1;

      //       this.panel = [];
      //       this.panel.push(latesIndex);
      //     }, 1000);
      //   }
      // });
    },
    postStatusSue() {
      this.$store.dispatch("loading/showLoading");

      let url = `/case/${this.state.id}/statusSue`;

      this.$http
        .post(url, this.payload)
        .then((res) => {
          this.showAlertSuccess();
          this.editedSuccess(res, true);
        })
        .finally(() => {
          this.$store.dispatch("loading/hideLoading");
        });
    },
    editCollapseInStorage(mode) {
      this.collapseMode = mode;
    },
  },
  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
    items: {
      get() {
        return this.state.sueItems;
      },
      set(val) {
        this.state.sueItems = val;
      },
    },
    itemsOrdered() {
      return _.orderBy(this.items, ["order"], ["desc"]);
    },
    hasAuthorize() {
      let permission = [
        UserRole.ADMIN,
        UserRole.MANAGEMENT,
        UserRole.BACK_OFFICE,
        UserRole.LAWYER,
      ];

      return this.hasPermission(permission);
    },
    statusSue() {
      return this.state.model.statusSue;
    },
    statusCase() {
      return this.state.model.statusCase;
    },

    showCondition() {
      let model = this.state.model;

      let result = false;
      if (!model.statusCase) {
        result = false;
      } else if (this.$store.getters["caseinfo/anotherConditionDisabledEdit"]) {
        return false;
      } else {
        if (StatusCase["รอวันฟ้องจากทนาย"].id === model.statusCase.id) {
          result = true;
        }

        if (StatusCase["แจ้งถอนฟ้อง"].id === model.statusCase.id) {
          result = true;
        }

        if (StatusCase["รอถอนฟ้อง"].id === model.statusCase.id) {
          result = true;
        }

        if (StatusCase["แจ้งงดหมายตั้ง"].id === model.statusCase.id) {
          result = true;
        }

        if (StatusCase["รอถอนหมายตั้ง"].id === model.statusCase.id) {
          result = true;
        }

        if (
          StatusCase["ส่งคำฟ้องให้ทนายแล้ว"].id === model.statusCase.id &&
          _.isNil(model.statusSue)
        ) {
          result = true;
        }

        if (
          StatusCase["ระหว่างยื่นฟ้อง"].id === model.statusCase.id &&
          model.statusSue.id !== StatusSue.ookmaiTang.id
        ) {
          result = true;
        }
      }

      if (!this.hasAuthorize) {
        result = false;
      }
      return result;
    },
    disabledExpandButton() {
      if (this.collapseMode === CollapseMode.expand) {
        return true;
      }

      return false;
    },
    disabledCollapseButton() {
      if (this.collapseMode === CollapseMode.collapse) {
        return true;
      }

      return false;
    },
    fieldConfig() {
      return {
        option: {
          name: "option",
          text: "Select Option",
          type: InputType.dropdown,
          rule: {
            required: true,
          },
          columnClass: "col-md-2",
          style: {
            width: "200px",
          },
        },
      };
    },
    payload() {
      let statusSue = _.cloneDeep(this.statusSue);
      if (statusSue) {
        statusSue.note =
          "หน้าบ้านไม่จำเป็นต้องส่งให้หลังบ้าน เพราะหลังบ้านไม่ควรใช้ Field นี้. แต่หลังบ้านต้องส่ง Field นี้กลับมาให้หน้าบ้าน";
      }
      return {
        caseId: this.state.id,
        statusCaseBeforePost: this.statusCase,
        statusSueBeforePost: this.statusSue,
        option: this.selectedOption,
        details: this.post,
      };
    },
  },
};
</script>

<style>
.case-number-prefix {
  display: inline-block;
  width: 50%;
}

.sue-number {
  display: inline-block;
  width: 50%;
}

.sue-number .v-text-field__slot label {
  display: none;
}
</style>
