import { InputType, ContractCondition } from '../../js/constants';
import { getFakeUrl, fakeAbbr } from '../../_helpers/fake-url';
import { aeonLeasingForm, getHeaders } from './aeon-utils-fields';
import * as http from '../../js/service';
import store from '../../_store/index';


let urlContractType = '/dropdown/AEONLeasingTypes';


export default function () {
    return {
        url: "/case/{caseId}/contract/aeonleasing",
        rows: [
            ...getHeaders(),
            //line 5
            [
                {
                    name: "contractType",
                    text: "ประเภทสัญญา",
                    type: InputType.dropdownRelate,
                    data: {
                        url: urlContractType
                    },
                    rule: {
                        required: true
                    }
                },
                {
                    name: "vehicleType",
                    text: "ประเภทรถ",
                    type: InputType.dropdown,
                    data: {
                        func: (context) => {

                            let url = `/dropdown/leasing/vehicleTypes`;

                            return http.get(url)
                        }
                    },
                    rule: {
                        required: true
                    }
                },
                {
                    name: "noticeType",
                    text: "ลักษณะสัญญา",
                    type: InputType.dropdown,
                    data: {
                        func: (context) => {

                            let url = `/dropdown/leasing/noticeType`;

                            return http.get(url)
                        }
                    },
                    rule: {
                        required: true
                    }
                }
            ],
            [
                {
                    name: "ven",
                    text: "ทะเบียนรถ",
                    type: InputType.text,
                },
                {
                    name: "brand",
                    text: "ยี่ห้อ",
                    type: InputType.text,
                },
                {
                    name: "model",
                    text: "รุ่น",
                    type: InputType.text,
                },
                {
                    name: "color",
                    text: "สี",
                    type: InputType.text,
                },
                {
                    name: "engineNumber",
                    text: "หมายเลขเครื่อง",
                    type: InputType.text,
                },
                {
                    name: "chassisNumber",
                    text: "หมายเลขตัวถัง",
                    type: InputType.text,
                }
            ],
            [
                {
                    name: "financingAmount",
                    text: "ยอดจัดเช่าซื้อ",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        // number: true,
                        required: true
                    },
                },
                // {
                //     name: "downPaymentAmount",
                //     text: "เงินดาวน์ (รวม VAT)",
                //     type: InputType.number,
                //     decimal: 2,
                //     rule: {
                //         // number: true,
                //         required: true
                //     },
                // },
                {
                    name: "flatRateInterestPerMonth",
                    text: "อัตราดอกเบี้ยคงที่ (Flat Rate) ต่อเดือน",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        // number: true,
                        required: true,
                    },
                    suffixText: "%",
                },
                {
                    name: "installments",
                    text: "จำนวนงวด (เดือน)",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        // number: true,
                        required: true
                    },
                },
                {
                    name: "paymentPerMonthIncludeVAT",
                    text: "ค่าเช่าซื้องวดละ (รวม VAT)",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        // number: true,
                        required: true
                    },
                },
                {
                    name: "effectiveVATRate",
                    text: "VAT",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        // number: true,
                        required: true
                    },
                    suffixText: "%",
                },
                {
                    type: InputType.offset
                }
            ],
            [
                {
                    name: "somePaidAmount",
                    text: "จำนวนที่จ่ายแล้วรวม (จาก Payment Inquiry)",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        // number: true,
                        required: true
                    },
                },
                {
                    name: "startPaymentDate",
                    text: "เริ่มชำระงวดแรกวันที่",
                    type: InputType.datePicker,
                },
                {
                    name: "pidnudChamraDate",
                    text: "วันที่ผิดนัดชำระ",
                    type: InputType.datePicker,
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_1)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    type: InputType.offset
                }

            ],
            [
                {
                    name: "lastPaidDate",
                    text: "ชำระล่าสุดวันที่ (จาก Payment Inquiry)",
                    type: InputType.datePicker,
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_1)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    name: "lastPaidAmount",
                    text: "จำนวนที่จ่ายครั้งล่าสุด (จาก Payment Inquiry)",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        required: true
                    },
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_1)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    type: InputType.offset
                },
                {
                    type: InputType.offset
                }

            ],
            [
                {
                    name: "inv_LastPaymentPrincipal",
                    text: "ยอดเงินต้น (จากใบเสร็จ)",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        required: true
                    },
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_1)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    name: "inv_LastPaymentInterest",
                    text: "ดอกเบี้ย (จากใบเสร็จ)",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        required: true,
                    },
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_1)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    name: "inv_LastPaymentVAT",
                    text: "VAT (จากใบเสร็จ)",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        required: true
                    },
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_1)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    name: "inv_PenaltyAmount",
                    text: "ค่าปรับ (จากใบเสร็จ)",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        required: true
                    },
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_1)) {
                            return true;
                        }

                        return false;
                    },
                },
            ],
            [
                {
                    name: "attorney",
                    text: "ผู้รับมอบอำนาจ",
                    type: InputType.text,
                },
                {
                    name: "subAttorney",
                    text: "ผู้รับมอบอำนาจช่วง",
                    type: InputType.text,
                },
                {
                    name: "hasTerminationLetter",
                    text: "มีหนังสือบอกเลิกสัญญา",
                    type: InputType.checkBox,
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_2)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    type: InputType.offset,
                }
            ],
            [
                {
                    name: "auctionDate",
                    text: "วันที่ขายทอดตลาด",
                    type: InputType.datePicker,
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_2)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    name: "auctionCompany",
                    text: "บริษัทที่ขายทอดตลาด",
                    type: InputType.dropdown,
                    data: {
                        url: '/dropdown/AuctionCompanies'
                        // promise: new Promise((resolve, reject) => {
                        //     let items = []
                        //     items.push({ id: '001', text: 'บริษัท สหการประมูล จำกัด (มหาชน)' })

                        //     resolve({ items })
                        // })
                    }
                    ,
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_2)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    name: "auctionPrice",
                    text: "ราคาขายทอดตลาด",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        required: true,
                    },
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_2)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    name: "paymentAfterAuction",
                    text: "ยอดชำระเข้ามาหลังขายทอดตลาด",
                    type: InputType.number,
                    decimal: 2,
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_2)) {
                            return true;
                        }

                        return false;
                    },
                },

            ],
            [
                {
                    name: "penaltyOutsource",
                    text: "ค่าติดตามภายนอก (ใบเสร็จ)",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        required: true,
                    },
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_2)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    name: "deptBalanceOfficial",
                    text: "ยอดหนี้คงค้างตามหนังสือแจ้ง",
                    type: InputType.number,
                    decimal: 2,
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_2)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    name: "priceDifference",
                    text: "ค่าขาดราคา",
                    type: InputType.number,
                    decimal: 2,
                    rule: {
                        required: true,
                    },
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_2)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    InputType: InputType.offset
                },
            ],
            [
                {
                    name: "damage",
                    text: "สภาพรถมีความเสียหาย กล่าวคือ",
                    type: InputType.textArea,
                    showIf: (context) => {
                        const model = context.model;
                        if (model.noticeType && model.noticeType.id.equals(ContractCondition.AEONLeasing_NoticeType_2)) {
                            return true;
                        }

                        return false;
                    },
                },
                {
                    InputType: InputType.offset
                },
                {
                    InputType: InputType.offset
                }
            ]
        ]
    };
}