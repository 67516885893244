import { ContractType } from './../../js/constants';
import AIRA_contract from './aira-contract';
import EAON_card_contract from './aeon-card-contract';
import EAON_loan_contract from './aeon-loan-contract';
import EAON_leasing_contract from './aeon-leasing-contract';
import rabbitContract from './rabbit-contract';


export function getUI(contractType) {
    if (contractType.toLowerCase() === ContractType.AIRA.toLowerCase()) {
        return AIRA_contract();
    }
    if (contractType.toLowerCase() === ContractType.AEON_CARD.toLowerCase()) {
        return EAON_card_contract();
    }
    if (contractType.toLowerCase() === ContractType.AEON_LOAN.toLowerCase()) {
        return EAON_loan_contract();
    }
    if (contractType.toLowerCase() === ContractType.AEON_LEASING.toLowerCase()) {
        return EAON_leasing_contract();
    }
    if (contractType.toLowerCase() === ContractType.RABBIT_CASH.toLowerCase()) {
        return rabbitContract();
    }
    if (contractType.toLowerCase() === ContractType.RABBIT_INSTALLMENT.toLowerCase()) {
        return rabbitContract();
    }
    if (contractType.toLowerCase() === ContractType.RABBIT_BENEFIT.toLowerCase()) {
        return rabbitContract();
    }
    if (contractType.toLowerCase() === ContractType.RABBIT_BENEFIT_SPECIAL.toLowerCase()) {
        return rabbitContract();
    }
}
