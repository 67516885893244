import { InputType, ContractCondition } from './../../js/constants';
import { getFakeUrl, fakeAbbr } from './../../_helpers/fake-url';
import { aeonForm } from './aeon-utils-fields';
import * as http from './../../js/service';
import store from './../../_store/index';


let urlCardType = '/dropdown/AEONCardTypes';
let urlCondition = '/dropdown/AEONCardTypeCondition?typeId=';
let urlSubCondition = '/dropdown/AEONCardTypeSubCondition?conditionId=';


export default function () {
    return {
        url: "/case/{caseId}/contract/aeoncard",
        rows: [
            ...aeonForm.header,
            //line 5
            [
                {
                    name: "contractType",
                    text: "ประเภทสัญญา",
                    type: InputType.dropdownRelate,
                    data: {
                        url: urlCardType
                    }
                },
                {
                    name: "condition",
                    text: "ลักษณะสัญญา",
                    type: InputType.dropdownRelate,
                    parent: "contractType",
                    data: {
                        url: urlCondition
                    }
                },
                {
                    name: "subCondition",
                    text: "เงื่อนไขสัญญา",
                    type: InputType.dropdownRelate,
                    parent: "condition",
                    data: {
                        url: urlSubCondition
                    },
                },
                {
                    name: "referenceContractId",
                    text: "รายการเลขที่สัญญาใน Case เดียวกัน",
                    type: InputType.dropdown,
                    data: {
                        func: (context) => {

                            // let caseId = store.state.caseinfo.id;

                            // let url = `/dropdown/contractsInCase?caseId=${caseId}`;
                            // if (context.model.id) {
                            //     url += `&contractId=${context.model.id}`;
                            // }

                            let url = `/dropdown/contractsInCase`;

                            let payload = {
                                caseId: store.state.caseinfo.id,
                                contractId: context.model.id
                            }

                            const fullUrl = url + "?" + $.param(payload);

                            return http.get(fullUrl)
                        }
                    },
                    showIf: (context) => {
                        const model = context.model;
                        if (model.condition && model.condition.id.equals(ContractCondition.AEONCard_Creditcard_AutoSwitch)) {
                            return true;
                        }

                        return false;
                    }
                }

            ],
            [
                {
                    name: "kyc",
                    text: "EKYC",
                    type: InputType.dropdown,
                    data: {
                        func: (context) => {
                            let url = `/dropdown/kycs`;

                            let payload = {
                                contractTypeId: context.model.contractTypeId
                            }

                            const fullUrl = url + "?" + $.param(payload);

                            return http.get(fullUrl)
                        }
                    },
                },
                {
                    type: InputType.offset
                },
                {
                    type: InputType.offset
                },
                {
                    type: InputType.offset
                }
            ],
            ...aeonForm.newFields,
        ]
    };
}