import { InputType, ContractCondition } from '../../js/constants';
import { getFakeUrl, fakeAbbr } from '../../_helpers/fake-url';
import { aeonForm } from './aeon-utils-fields';
import * as http from './../../js/service';



let urlLoanType = '/dropdown/AEONLoanTypes';
let urlCondition = '/dropdown/AEONLoanTypeCondition?typeId=';
let urlSubCondition = '/dropdown/AEONLoanTypeSubCondition?conditionId=';


export default function () {
    return {
        url: "/case/{caseId}/contract/aeonloan",
        rows: [
            ...aeonForm.header,
            //line 5
            [
                {
                    name: "contractType",
                    text: "ประเภทสัญญา",
                    type: InputType.dropdownRelate,
                    data: {
                        url: urlLoanType
                    }
                },
                {
                    name: "condition",
                    text: "ลักษณะสัญญา",
                    type: InputType.dropdownRelate,
                    parent: "contractType",
                    data: {
                        url: urlCondition
                    }
                },
                {
                    name: "subCondition",
                    text: "เงื่อนไขสัญญา",
                    type: InputType.dropdownRelate,
                    parent: "condition",
                    data: {
                        url: urlSubCondition
                    },
                },
                {
                    type: InputType.offset
                },
            ],
            [
                {
                    name: "kyc",
                    text: "EKYC",
                    type: InputType.dropdown,
                    data: {
                        func: (context) => {
                            let url = `/dropdown/kycs`;

                            let payload = {
                                contractTypeId: context.model.contractTypeId
                            }

                            const fullUrl = url + "?" + $.param(payload);

                            return http.get(fullUrl)
                        }
                    },
                },
                {
                    type: InputType.offset
                },
                {
                    type: InputType.offset
                },
                {
                    type: InputType.offset
                }
            ],
            //line 6
            ...aeonForm.newFields,
            [
                {
                    name: "subContracts",
                    type: InputType.refinanceList,
                    default: [],
                    showIf: (context) => {
                        const model = context.model;

                        if (model.condition && model.condition.id.equals(ContractCondition.AEONLoan_General_PranomNee)) {
                            return true;
                        }

                        return false;
                    }
                }
            ]
        ]
    };
}